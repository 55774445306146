import React from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { mergeClass } from "../../../functions/function";
import Icon from "../../../icon/Icon";
import DateCustom from "../date/DateCustom";
import { inputType } from "../../../../../utils/forms/models/FormElementModel";

const Input: React.FC<inputType> = ({
  children,
  className,
  icon,
  firstLabel,
  type,
  error = false,
  value = "",
  onChangeAction = () => {},
  buttonIcon = "",
  buttonIconClick,
  ...rest
}) => {
  const classMerge: string = mergeClass("shared-input", error ? " error" : "");
  const changeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeAction &&
      onChangeAction(
        type === "checkbox"
          ? event.target.checked
          : type === "number"
          ? +event.target.value
          : event.target.value,
        event
      );
  };
  return (
    <InputGroup className="sharedInputGroup">
      {(icon || firstLabel) && (
        <InputGroup.Prepend>
          <InputGroup.Text id={"icon-" + icon}>
            {icon && <Icon icon={icon}></Icon>}
            {firstLabel && <span>{firstLabel}</span>}
          </InputGroup.Text>
        </InputGroup.Prepend>
      )}
      {buttonIcon && buttonIcon.length ? (
        <InputGroup.Append className="align-items-center">
          <Icon
            icon={buttonIcon}
            onClick={() => buttonIconClick && buttonIconClick()}
          ></Icon>
        </InputGroup.Append>
      ) : null}
      {type === "date" ? (
        <DateCustom
          value={value}
          error={error}
          onChangeAction={onChangeAction}
          {...rest}
        />
      ) : (
        <FormControl
          type={type}
          className={mergeClass(classMerge, className)}
          onChange={changeInput}
          value={value}
          {...rest}
        />
      )}
    </InputGroup>
  );
};

export default Input;
