import { IGenericSize, IScreenSize } from "../app/auth/model/screenUtil";
import { MAX_SIZE_IMAGE } from "../features/auth/projectMap/pinData";

export const resizeImg = (
  sizeImage: IGenericSize,
  maxSize = MAX_SIZE_IMAGE
) => {
  const greaterSize =
    sizeImage.width >= sizeImage.height ? sizeImage.width : sizeImage.height;
  const parametroMax = maxSize;
  let newHImg;
  let newWImg;

  if (greaterSize === sizeImage.width) {
    newHImg = Math.floor((sizeImage.height * parametroMax) / sizeImage.width);
    newWImg = parametroMax;
  } else {
    newHImg = parametroMax;
    newWImg = Math.floor((sizeImage.width * parametroMax) / sizeImage.height);
  }

  return { width: newWImg, height: newHImg };
};

export const proportionalResize = (dimension: number, proportion: number) => {
  return dimension * proportion;
};

export const resizeImgToScreen = (img: any, screenSize: IScreenSize) => {
  let originGeometry = {
    width: img?.width || 0,
    height: img?.height || 0,
  };
  let diffWidth = originGeometry.width - screenSize.widthWindow;
  let diffHeight = originGeometry.height - screenSize.heightWindow;
  return diffWidth > diffHeight
    ? {
        width: screenSize.widthWindow,
        height:
          originGeometry.height *
          (screenSize.widthWindow / originGeometry.width),
        coff: screenSize.widthWindow / originGeometry.width,
      }
    : {
        height: screenSize.heightWindow,
        width:
          originGeometry.width *
          (screenSize.heightWindow / originGeometry.height),
        coff: screenSize.heightWindow / originGeometry.height,
      };
};

export const proportionalResizeCanvas = (screenSize: IScreenSize) => {
  const coeff = 1920 / 1080;
  let widthCanvas = screenSize.widthWindow;
  let heightCanvas = widthCanvas / coeff;
  if (heightCanvas > screenSize.heightWindow) {
    return {
      height: screenSize.heightWindow,
      width: coeff * screenSize.heightWindow,
    };
  } else {
    return {
      height: heightCanvas,
      width: widthCanvas,
    };
  }
};

export const resizeImageToCanvasProportion = (
  canvasWidth: number,
  imageWidth: number
) => {
  return (canvasWidth * imageWidth) / 1920;
};

export const resizeImageFromCanvasProportion = (
  canvasWidth: number,
  imageWidth: number
) => {
  return (1920 * imageWidth) / canvasWidth;
};

export const resizeImageToStandard = (sizeImage: IGenericSize) => {
  let newSize: IGenericSize = { width: 0, height: 0 };
  if (sizeImage.width < 1920 && sizeImage.height < 1080) {
    return sizeImage;
  } else {
    newSize.width = 1920;
    newSize.height = (1920 / 1080) * sizeImage.height;
    if (newSize.height > window.innerHeight) {
      return {
        height: window.innerHeight,
        width: window.innerHeight * (1080 / 1920),
      };
    } else {
      return newSize;
    }
  }
};
