import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import http from "../utils/http";

export const seti18nPublic = (setLoadI18nPublic: (data: boolean) => void) => {
  const langBrowser = navigator.language.trim().split(/-|_/)[0];
  http
    .get(
      `https://g4mobility-stage.fra1.cdn.digitaloceanspaces.com/i18n/${langBrowser}.json`
    )
    .then((res) => {
      const resources: any = {
        [langBrowser]: {
          translation: res,
        },
      };

      i18n
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
          resources,
          initImmediate: false,
          //lng: "it", //  commented to make languagedetector working. enable if u want to override missing strings with the fallback lang
          fallbackLng: "it",
          supportedLngs: ["it", "en"],
          interpolation: {
            escapeValue: false,
          },
        });
      setLoadI18nPublic(true);
    });
};
export default i18n;
