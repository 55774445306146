import React from "react";
import { useTranslation } from "react-i18next";
import { permissionType } from "../../../app/rolePermissions";
import ToolbarButton from "../../../components/shared/custom/toolbar/toolbarButton/ToolbarButton";
import IsPermission from "../../../components/shared/IsPermission";

const EditModeBtn = ({ handlerAction }: { handlerAction: () => void }) => {
  const { t } = useTranslation();

  return (
    <IsPermission permissions={[permissionType.ROLE_ADMIN]} or={true}>
      <ToolbarButton
        clickAction={handlerAction}
        icon="app"
        title={t("APP.EDITMODE")}
      />
    </IsPermission>
  );
};

export default EditModeBtn;
