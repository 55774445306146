import React, { useEffect } from "react";
import { useAuthentication } from "../Authentication";

function LoggedIn(_props: { children: any }) {
  const { isAuthenticated, status } = useAuthentication();

  return (
    <div>{isAuthenticated() && status === "LOGGED" && _props.children}</div>
  );
}

export default LoggedIn;
