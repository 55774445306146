import React from "react";
import { useTranslation } from "react-i18next";
import Icon from "../../../../../../components/shared/icon/Icon";
import {
  IMicrobadgeItem,
  IMicroBadgeResponse,
} from "../models/serviceResponse";

const MicroBadge = ({
  microbadgeList,
}: {
  microbadgeList: IMicroBadgeResponse | undefined;
}) => {
  const { t } = useTranslation();
  const getIcon = (microbadge: IMicrobadgeItem) => {
    if (microbadge.obtained) {
      return <Icon icon="stars" colorStyle="#01a381" />;
    } else {
      return <Icon icon="stars" colorStyle="#a10000" />;
    }
    // if (microbadge.expired) {
    //     return <Icon icon="delete" colorStyle="#a10000" />
    // }
  };
  return (
    <>
      <div className="microbadge-container">
        {microbadgeList &&
          microbadgeList.microbadgeItems.map((micro, index) => {
            return (
              <div className="p-1" key={index}>
                {getIcon(micro)}
                {t(micro.description)}
              </div>
            );
          })}
      </div>
    </>
  );
};

export default MicroBadge;
