import http from "../../../../../../utils/http";
import { IMessageManagement } from "../../../../comments/models/config";
import { IMessageContent, IPageableManage } from "../model/config";

const urlComment = "/messages";

export const getMessages = ({
  id = "0",
  accepted = false,
  obsoleted = false,
  page = 0,
  size = 10,
}: IPageableManage): Promise<IMessageManagement> =>
  http.get(
    `${urlComment}/${id}/comment?accepted=${accepted}&obsoleted=${obsoleted}&page=${page}&size=${size}`
  );

export const getMessageById = (
  idCat: string,
  idMessage: number
): Promise<IMessageManagement> =>
  http.get(`${urlComment}/${idCat}/comment/${idMessage}`);

export const getMessageByView = ({
  id,
  page = 0,
  size = 5,
}: {
  id: string;
  page: number;
  size: number;
}): Promise<IMessageManagement> =>
  http.get(`${urlComment}/${id}/comment/view?page=${page}&size=${size}`);

export const updateStatusMessage = (
  editedMessage: IMessageContent,
  idCat: string = "0"
): Promise<any> => {
  const newMessage = {
    accepted: editedMessage.accepted,
    obsoleted: editedMessage.obsoleted,
  };
  return http.patch(
    `${urlComment}/${idCat}/comment/${editedMessage.id}`,
    newMessage
  );
};

export const deleteMessage = (idCat: string, idMessage: number): Promise<any> =>
  http.delete(`${urlComment}/${idCat}/comment/${idMessage}`);
