import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import HeadingH3 from "../../../../../components/shared/headings/headingH3/HeadingH3";
import BadgeContainer from "./components/BadgeContainer";
import BadgeTabs from "./components/BadgeTabs";
import { cardTitleProgress, tabsNameProgress } from "./myProgressData";
import {
  getListBadges,
  selectBadgesList,
  selectKeyTabsProgress,
  setClickedAreaId,
  setKeyTabProgress,
} from "./myProgressSlice";

const MyProgress = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const tab = useSelector(selectKeyTabsProgress);
  const listBadges = useSelector(selectBadgesList);
  const changeTabsBadge = (tab: string) => {
    dispatch(setClickedAreaId(""));
    dispatch(setKeyTabProgress(tab));
  };
  useEffect(() => {
    dispatch(getListBadges());
  }, []);

  const cardTitle =
    tab === tabsNameProgress.active
      ? t(cardTitleProgress.active)
      : t(cardTitleProgress.expired);

  const activeBadges = listBadges ? listBadges.perimeterBadgeActive : [];
  const expiredBadges = listBadges ? listBadges.perimeterBadgeExpired : [];

  return (
    <>
      <BadgeTabs changeTabsBadge={changeTabsBadge} tab={tab} />
      <div className="myprogress-container">
        <BadgeContainer
          cardTitle={cardTitle}
          listBadges={
            tab === tabsNameProgress.active ? activeBadges : expiredBadges
          }
        />
      </div>
    </>
  );
};

export default MyProgress;
