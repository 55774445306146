import React from "react";
import MenuLayout from "../../features/auth/menu/MenuLayout";
import authRoute from "../../routes/auth";
import { Routes, SingleRoute } from "../../utils/routes/models/routes";
import DynamicRoute from "./DynamicRoute";

const AuthLayout = () => {
  const routeList: Routes = {
    list: authRoute.list.reduce(
      (arr: SingleRoute[], routeGroup) => [
        ...arr,
        ...routeGroup.childrenRoute.map((ele) => ({
          ...ele,
          path: routeGroup.path + ele.path,
        })),
      ],
      []
    ),
    redirect: authRoute.redirect,
  };
  const layouts = [
    {
      name: "menuLayout",
      component: (data?: React.ReactNode) => <MenuLayout>{data}</MenuLayout>,
    },
  ];

  return (
    <div className="auth">
      <DynamicRoute routes={routeList} layouts={layouts} />
    </div>
  );
};

export default AuthLayout;
