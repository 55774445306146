import { copyObject, isObject } from "./objUtils";

export const clearEmptyProperty = (obj: { [key: string]: any } = {}) => {
  if (isObject(obj)) {
    const copyObj: any = copyObject(obj);
    return Object.keys(copyObj).reduce((obj: any, ele: string) => {
      if (typeof copyObj[ele] === "string") {
        obj[ele] = setEmptyUndefined(copyObj[ele]);
      } else if (isObject(copyObj[ele])) {
        obj[ele] = clearEmptyProperty(copyObj[ele]);
      } else {
        obj[ele] = copyObj[ele];
      }
      return obj;
    }, {});
  } else {
    return obj;
  }
};

export const isEmpty = (value: any) => {
  return value === undefined || value === "";
};

export const setEmptyUndefined = (value: any) => {
  return isEmpty(value) ? undefined : value;
};
