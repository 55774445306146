import { TFunction } from "i18next";
import {
  required,
  email,
} from "./../../../../utils/forms/utils/validationType";
import { createValidationsForm } from "./../../../../utils/forms/useStructureForm";
import { FormElementModelNew } from "./../../../../utils/forms/models/FormElementModel";
import { useTranslation } from "react-i18next";
import { createStructureForm } from "../../../../utils/forms/useStructureForm";

export const useEditProfile = (t: TFunction, dropdownSelectableLangs: any) => {
  const validationsData = createValidationsForm([
    {
      name: "name",
      validations: [
        {
          name: required,
          params: { name: "name" },
        },
      ],
    },
    {
      name: "surname",
      validations: [
        {
          name: required,
          params: { name: "surname" },
        },
      ],
    },
    {
      name: "birthDate",
      validations: [
        {
          name: required,
          params: { name: "birthDate" },
        },
      ],
    },
    {
      name: "email",
      validations: [
        {
          name: required,
          params: { name: "email" },
        },
        {
          name: email,
          params: {
            name: "email",
          },
        },
      ],
    },
    {
      name: "language",
      validations: [
        {
          name: required,
          params: { name: "language" },
        },
      ],
    },
  ]);
  const buttonList = [
    {
      label: t("MODAL.CANCELL"),
      action: "cancelDelete",
      type: "secondary",
      dataType: {},
    },
    {
      label: t("MODAL.CONFIRM"),
      action: "confirmDelete",
      type: "primary",
      dataType: {},
    },
  ];
  const userRow: FormElementModelNew[] = createStructureForm([
    {
      typeElement: "input",
      label: t("PUBLIC.NAME"),
      name: "name",
      dataElement: {
        placeholder: t("PUBLIC.NAME"),
        type: "text",
        disabled: false,
      },
      col: { xs: 12, md: 6, lg: 4 },
    },
    {
      typeElement: "input",
      label: t("PUBLIC.SURNAME"),
      name: "surname",
      dataElement: {
        placeholder: t("PUBLIC.SURNAME"),
        type: "text",
        disabled: false,
      },
      col: { xs: 12, md: 6, lg: 4 },
    },
    {
      typeElement: "input",
      label: t("PUBLIC.BIRTHDATE"),
      name: "birthDate",
      dataElement: {
        placeholder: t("PUBLIC.BIRTHDATE"),
        type: "date",
        disabled: false,
      },
      col: { xs: 12, md: 6, lg: 4 },
    },
    {
      typeElement: "select",
      label: t("PUBLIC.GENDER.TITLE"),
      name: "gender",
      options: [
        { label: t("APP.FORM.CHOOSE"), value: "-1" },
        { label: t("PUBLIC.GENDER.MALE"), value: "1" },
        { label: t("PUBLIC.GENDER.FEMALE"), value: "2" },
      ],
      dataElement: {
        placeholder: t("PUBLIC.GENDER"),
        type: "text",
        disabled: false,
      },
      col: { xs: 12, md: 6, lg: 4 },
    },
    {
      typeElement: "input",
      label: t("PUBLIC.EMAIL"),
      name: "email",
      dataElement: {
        placeholder: t("PUBLIC.EMAIL"),
        type: "text",
        disabled: false,
      },
      col: { xs: 12, md: 6, lg: 4 },
    },
    {
      typeElement: "input",
      label: t("PUBLIC.TITLEOFSTUDYCODE"),
      name: "titleOfStudyCode",
      dataElement: {
        placeholder: t("PUBLIC.TITLEOFSTUDYCODE"),
        type: "text",
        disabled: false,
      },
      col: { xs: 12, md: 6, lg: 4 },
    },
    {
      typeElement: "select",
      label: t("PUBLIC.LANGUAGE"),
      name: "language",
      dataElement: {
        placeholder: t("PUBLIC.LANGUAGE"),
        type: "text",
        disabled: false,
      },
      options: dropdownSelectableLangs,
      col: { xs: 12, md: 6, lg: 4 },
    },
  ]);
  return { validationsData, buttonList, userRow };
};
