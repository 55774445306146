export const initialPositionMap = {
  lat: 45.3568045656609,
  lng: 10.954052540764678,
};

export const initialZoomLevelMap = 16;

export const mapUrl =
  "https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png";

// "https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png" //edifici grigio chiaro e fiume azzurro senza ondine ma con numeri civici!
// "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}" //edifici normali grigio scuro e alcuni specifici colorati e fiume azzurro senza ondine
// "https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}{r}.png" //alcune vie gialle, edifici grigio chiaro e fiume azzurro con ondine
// "https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"; //desaturata, grigia (quella usata nello sviluppo all'inizio)
export const mapAttribution =
  '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors';

//------------------------>>
// desaturata, grigia (quella usata nello sviluppo all'inizio)
// export const mapUrl = "https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"
// export const mapAttribution =
// '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors';

//------------------------>>
// alcune vie gialle, edifici grigio chiaro e fiume azzurro con ondine
// export const mapUrl = "https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}{r}.png"
// export const mapAttribution =
// '<a href="https://wikimediafoundation.org/wiki/Maps_Terms_of_Use">Wikimedia</a>'

//------------------------>>
// edifici normali grigio scuro e alcuni specifici colorati e fiume azzurro senza ondine
// export const mapUrl = "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}"
// export const mapAttribution =
// 'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC, USGS, FAO, NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), and the GIS User Community'

//------------------------>>
// edifici grigio chiaro e fiume azzurro senza ondine ma con numeri civici!
// export const mapUrl = "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}"
// export const mapAttribution =
// '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
