import http from "../../../../../utils/http";

const urlLoadGame = `/microgame`;

export const getListGameByArea = (areaGame: string): Promise<any> =>
  http.get(`${urlLoadGame}/listMicrogameByArea/${areaGame}`);

export const getMicroCategoryByFatherId = (fatherId: string): Promise<any> =>
  http.get(`${urlLoadGame}/microgameconfigByFatherId/${fatherId}`);

export const getListMicroGameByArea = (areaGame: string): Promise<any> =>
  http.get(`/game-configuration/microgame?area=${areaGame}`);
