import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../app/store";
import { ErrorStateProps } from "../../design/form/models/FormModule";

const initialState: { n: number; manual: boolean } = {
  n: 0,
  manual: false,
};

export const loadSlice = createSlice({
  name: "load",
  initialState,
  reducers: {
    incrementN: (state) => {
      state.n = state.n + 1;
    },
    decrementN: (state) => {
      state.n = state.n - 1;
    },
    setLoadManual: (state, action: PayloadAction<boolean>) => {
      state.manual = action.payload;
    },
  },
});

export const { incrementN, decrementN, setLoadManual } = loadSlice.actions;

export const selectActiveLoad = (state: RootState) => !!state.load.n;
export const selectActiveManualLoad = (state: RootState) => state.load.manual;

export default loadSlice.reducer;
