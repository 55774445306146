export const defaultDataToolbarBtn = <T>(
  counter: number,
  history: T[],
  options?: {
    showBtnListLeft?: boolean;
    showBtnListRight?: boolean;
    showBtnListRightCheck?: boolean;
  }
) => {
  const btnListLeft =
    options?.showBtnListLeft === false
      ? []
      : [
          {
            label: "APP.EDITIMG",
            icon: "pin_drop",
            color: "#34a381",
            action: "editImg",
            title: "APP.EDITIMG",
          },
        ];

  const btnListLeftCheck: any[] = [];

  const btnListRight =
    options?.showBtnListRight === false
      ? []
      : [
          {
            label: "APP.RESTORE",
            icon: "history",
            color: "#34a381",
            action: "history",
            title: "APP.RESTORE",
          },
          {
            label: "APP.UNDO",
            icon: "undo",
            color: "#34a381",
            action: "undo",
            disabled: counter <= 0,
            title: "APP.UNDO",
          },
          {
            label: "APP.REDO",
            icon: "redo",
            color: "#34a381",
            action: "redo",
            disabled: history.length - 1 === counter,
            title: "APP.REDO",
          },
          {
            label: "APP.DELETE",
            icon: "delete",
            color: "#34a381",
            action: "delete",
            title: "APP.DELETE",
          },
          {
            label: "APP.SAVE",
            icon: "save",
            color: "#34a381",
            action: "save",
            title: "APP.SAVE",
          },
        ];

  const btnListRightCheck =
    options?.showBtnListRightCheck === false
      ? []
      : [
          {
            label: "APP.SAVE",
            icon: "save",
            color: "#34a381",
            action: "save",
            title: "APP.SAVE",
          },
        ];

  return { btnListRight, btnListRightCheck, btnListLeft, btnListLeftCheck };
};
export default defaultDataToolbarBtn;
