import isEqual from "lodash.isequal";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import defaultDataToolbarBtn from "../../../../../components/shared/custom/sidebar/defaultDataToolbarBtn/DefaultDataToolbarBtn";
import ToolbarBtn from "../../../../../components/shared/custom/sidebar/toolbarBtn/ToolbarBtn";
import useStateHistory from "../../../../../hooks/useStateHistory";
import FormElements from "../../../../../utils/forms/FormElements";
import { UseFormSubmitModel } from "../../../../../utils/forms/models/UseFormModel";
import { useForm } from "../../../../../utils/forms/useForm";
import {
  getObjForm,
  setObjForm,
} from "../../../../../utils/forms/useStructureForm";
import { Report } from "../../types";
import useReportForm from "./useReportForm";
import { validations } from "./validationForm";

type Props = {
  actualReport: Report;
  onSave: (data: Report) => void;
  onDelete: () => void;
};

function MetabaseForm(props: Props) {
  const { actualReport, onDelete, onSave } = props;

  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);

  const [reportForm, setReportForm, { history, index, redo, undo, reset }] =
    useStateHistory(actualReport);

  const { btnListRight, btnListLeft } = defaultDataToolbarBtn(index, history);

  const submitHandler: UseFormSubmitModel = (event, dataSubmit: any) => {
    onSave(dataSubmit);
  };

  const validationsData = validations(reportForm);

  const form = useForm(submitHandler, validationsData);
  const {
    isSubmit,
    errors,
    submitAction,
    changeValue,
    dataForm,
    setData,
    dataFormatted,
  } = form;
  const { dataStructure } = useReportForm(t, dataForm);

  useEffect(() => {
    const toForm = setObjForm(actualReport);
    if (!isEqual(toForm, dataForm)) {
      setData(toForm);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (!loading) {
      const dataFormatted = getObjForm(dataForm);
      if (!isEqual(dataFormatted, reportForm)) {
        setReportForm(dataFormatted);
      }
    }
  }, [dataForm]);

  useEffect(() => {
    if (reportForm && !loading) {
      const toForm = setObjForm(reportForm);
      if (!isEqual(toForm, dataForm)) {
        setData(toForm);
      }
    }
  }, [reportForm]);

  const toolbarAction: { [key in string]: () => void } = {
    save: submitAction,
    history: reset,
    undo: undo,
    redo: redo,
    delete: onDelete,
  };

  return (
    <div>
      <ToolbarBtn
        btnListLeft={btnListLeft}
        btnListRight={btnListRight}
        clickAction={(action) => toolbarAction[action]()}
      />
      <Form onSubmit={submitAction}>
        <FormElements
          data={dataStructure}
          dataFormatted={dataFormatted}
          errors={errors}
          isSubmit={isSubmit}
          dataForm={dataForm}
          changeValue={changeValue}
        />
      </Form>
    </div>
  );
}

export default MetabaseForm;
