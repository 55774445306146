import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { copyObject } from "../../../utils/objUtils";
import { useRoute } from "../../../utils/routes/useRoute";
import { arrayObjSort } from "../../../utils/sortArray";
import { IMenuItem } from "./models/menuItem";
import SubMenuItem from "./SubMenuItem";

const MenuItem = ({
  itemList,
  handleClickLevel,
  handleClickSubLevel,
  idMenuAperto,
}: {
  itemList: IMenuItem[];
  handleClickLevel: (ele: IMenuItem) => void;
  handleClickSubLevel: (route: string) => void;
  idMenuAperto: string;
}) => {
  const { t } = useTranslation();
  const { checkActiveLink } = useRoute();

  const [activesubItem, setActivesubItem] = useState("");

  const handleLevel = (ele: IMenuItem) => {
    handleClickLevel(ele);
  };
  const handleSubLevel = (route: string) => {
    handleClickSubLevel(route);
    checkActiveLink(route) && setActivesubItem(route);
  };
  const history = useHistory();
  const actualRoute = history.location.pathname.split("/").slice(-1)[0];

  useEffect(() => {
    setActivesubItem(actualRoute);
  }, [actualRoute]);

  const menu = copyObject(itemList);
  const copyMenu = arrayObjSort(menu, "orderNumber");

  return (
    <>
      {copyMenu.map((ele: IMenuItem) => {
        return (
          <li key={ele._id}>
            <span onClick={() => handleLevel(ele)} title={t(ele.label)}>
              <i className={`${ele.icon}`}></i>
              <span
                className={`item ${checkActiveLink(ele.route) ? "active" : ""}`}
              >
                {t(ele.label)}
              </span>
            </span>
            {ele.childrenItems && ele.childrenItems.length > 0 && (
              <ul
                className={`list-unstyled sub-components ${
                  idMenuAperto === ele._id ? "open" : ""
                }`}
              >
                <SubMenuItem
                  itemList={ele.childrenItems}
                  handleClickSubLevel={(route: string) => handleSubLevel(route)}
                  activesubItem={activesubItem}
                />
              </ul>
            )}
          </li>
        );
      })}
    </>
  );
};

export default MenuItem;
