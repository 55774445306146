import React from "react";
import { useAuthentication } from "../Authentication";

function Anonymous(_props: { children: any }) {
  const { isAuthenticated, status } = useAuthentication();

  return (
    <div>{!isAuthenticated() && status === "LOGGING" && _props.children}</div>
  );
}

export default Anonymous;
