import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  addErrorMessage,
  addSuccessMessage,
} from "../../../app/errors/errorSlice";
import { AppThunk, RootState } from "../../../app/store";
import i18n from "../../../config/i18n";
import {
  getAclById,
  getAclByName,
} from "../menu/menuLevels/aclManagement/service/acl.service";
import { ICheckItem } from "../menu/menuLevels/checkManagement/model/config";
import { addCheck } from "../menu/menuLevels/checkManagement/service/check.service";
import {
  createCheck,
  getCheckDefinitionById,
  getCheckListByName,
  removeCheck,
  updateCheckById,
} from "./service/check.service";

export interface ICheckSlice {
  argumentsList: string[];
  categoriesList: any[];
  namesList: any[];
  selectedCheck?: ICheckItem;
  checkNamesList: ICheckItem[];
  categoriesListInEdit: any[];
}
const initialState: ICheckSlice = {
  argumentsList: [],
  namesList: [],
  categoriesList: [],
  selectedCheck: undefined,
  checkNamesList: [],
  categoriesListInEdit: [],
};

export const checkSlice = createSlice({
  name: "check",
  initialState,
  reducers: {
    setListArguments: (state, action: PayloadAction<string[]>) => {
      state.argumentsList = action.payload;
    },
    setListNames: (state, action: PayloadAction<any[]>) => {
      state.namesList = action.payload;
    },
    setListCategories: (state, action: PayloadAction<any[]>) => {
      state.categoriesList = action.payload;
    },
    setSelectedCheck: (state, action: PayloadAction<ICheckItem>) => {
      state.selectedCheck = action.payload;
    },
    setCheckNamesList: (state, action: PayloadAction<any[]>) => {
      state.checkNamesList = action.payload;
    },
    setCategoriesListInEdit: (state, action: PayloadAction<any[]>) => {
      state.categoriesListInEdit = action.payload;
    },
    resetListArguments: (state) => {
      state.argumentsList = [];
    },
    resetListNames: (state) => {
      state.namesList = [];
    },
    resetListCategories: (state) => {
      state.categoriesList = [];
    },
    resetSelectedCheck: (state) => {
      state.selectedCheck = undefined;
    },
    resetCheckNamesList: (state) => {
      state.checkNamesList = [];
    },
    removeArgumentFromList: (state, action: PayloadAction<any>) => {
      state.argumentsList = [
        ...state.argumentsList.filter(
          (argument) => argument !== action.payload
        ),
      ];
    },
    removeCategoryFromList: (state, action: PayloadAction<any>) => {
      state.categoriesList = [
        ...state.categoriesList.filter(
          (category) => category !== action.payload
        ),
      ];
    },
    addArgumentInList: (state, action: PayloadAction<any>) => {
      state.argumentsList = [
        ...state.argumentsList.filter(
          (argument) => argument !== action.payload
        ),
        action.payload,
      ];
    },
    addCategoryInList: (state, action: PayloadAction<any>) => {
      state.categoriesList = [
        ...state.categoriesList.filter(
          (category) => category !== action.payload
        ),
        action.payload,
      ];
    },
    editArgumentInList: (state, action: PayloadAction<any>) => {
      state.argumentsList = [
        ...state.argumentsList.filter(
          (argument) => argument !== action.payload
        ),
        action.payload,
      ];
    },
  },
});
export const {
  setListArguments,
  setListNames,
  setSelectedCheck,
  setListCategories,
  setCheckNamesList,
  setCategoriesListInEdit,
  resetListArguments,
  resetListNames,
  resetListCategories,
  resetSelectedCheck,
  resetCheckNamesList,
  removeArgumentFromList,
  removeCategoryFromList,
  addArgumentInList,
  addCategoryInList,
  editArgumentInList,
} = checkSlice.actions;

export const resetAllForm = (): AppThunk => (dispatch) => {
  dispatch(resetListArguments());
  dispatch(resetListNames());
  dispatch(resetListCategories());
  dispatch(resetSelectedCheck());
  dispatch(resetCheckNamesList());
};

export const getNamesList =
  (name: string): AppThunk =>
  (dispatch) => {
    getAclByName(name).then(
      (res) => {
        dispatch(setListNames(res));
      },
      (err) =>
        dispatch(
          addErrorMessage({
            title: i18n.t("ERRORSMSG.LOADINGNAMES"),
          })
        )
    );
  };

export const getCheckNamesList =
  (name: string): AppThunk =>
  (dispatch) => {
    getCheckListByName(name).then(
      (res) => {
        dispatch(setCheckNamesList(res));
      },
      (err) =>
        dispatch(
          addErrorMessage({
            title: i18n.t("ERRORSMSG.LOADINGCHECKS"),
          })
        )
    );
  };

export const getNamesListById =
  (id: string): AppThunk =>
  (dispatch) => {
    getAclById(id).then(
      (res) => {
        dispatch(setListNames([res]));
      },
      (err) =>
        dispatch(
          addErrorMessage({
            title: i18n.t("ERRORSMSG.LOADINGNAMES"),
          })
        )
    );
  };

export const getCheck =
  (id: string): AppThunk =>
  (dispatch) => {
    id &&
      getCheckDefinitionById(id).then(
        (res) => {
          dispatch(setSelectedCheck(res));
          dispatch(setCheckNamesList([res]));
        },
        (err) =>
          dispatch(
            addErrorMessage({
              title: i18n.t("ERRORSMSG.SEARCHACL"),
            })
          )
      );
  };

export const setCheck =
  (selectedCheck: ICheckItem): AppThunk =>
  (dispatch, getState) => {
    let parameters = selectedCheck.paramenter;
    dispatch(setListArguments(parameters));
    let categoryListToSet: any[] = [];
    let categoriesIdList = selectedCheck.categories;
    let categoriesList = getState().categories.categories;
    for (const idCat of categoriesIdList) {
      let aaa = categoriesList.map((category) => {
        if (category.id === idCat) {
          categoryListToSet.push(`${category.name + "-" + category.id}`);
        }
      });
    }
    dispatch(setListCategories(categoryListToSet));
  };

export const createNewCheck =
  (data: ICheckItem, callBack: () => void): AppThunk =>
  (dispatch, getState) => {
    let listNames = getState().check.namesList;
    let findName = listNames.filter((name: any) => name.name === data.acl);
    let newData = { ...data };
    newData.acl = findName[0]._id;
    createCheck(newData).then(
      (res) => {
        callBack && callBack();
        dispatch(resetAllForm());
        dispatch(
          addSuccessMessage({
            title: i18n.t("SUCCESSMSG.SAVECHECK"),
          })
        );
      },
      (err) =>
        dispatch(
          addErrorMessage({
            title: i18n.t("ERRORSMSG.SAVECHECK"),
          })
        )
    );
  };

export const updateActionCheck =
  (data: ICheckItem, callBack: () => void): AppThunk =>
  (dispatch, getState) => {
    let newData = { ...data };
    data._id &&
      updateCheckById(data._id, newData).then(
        (res) => {
          callBack && callBack();
          dispatch(resetAllForm());
          dispatch(
            addSuccessMessage({
              title: i18n.t("SUCCESSMSG.SAVECHECK"),
            })
          );
        },
        (err) =>
          dispatch(
            addErrorMessage({
              title: i18n.t("ERRORSMSG.SAVECHECK"),
            })
          )
      );
  };

export const saveActionCheck =
  (data: ICheckItem): AppThunk =>
  (dispatch) => {
    addCheck(data).then(
      (res) => {
        dispatch(
          addSuccessMessage({
            title: i18n.t("SUCCESSMSG.SAVECHECK"),
          })
        );
      },
      (err) =>
        dispatch(
          addErrorMessage({
            title: i18n.t("ERRORSMSG.SAVECHECK"),
          })
        )
    );
  };

export const selectListArguments = (state: RootState) =>
  state.check.argumentsList;
export const selectListNames = (state: RootState) => state.check.namesList;
export const selectListCategories = (state: RootState) =>
  state.check.categoriesList;
export const selectSelectedCheck = (state: RootState) =>
  state.check.selectedCheck;
export const selectListCheckNames = (state: RootState) =>
  state.check.checkNamesList;
export const selectCategoriesListInEdit = (state: RootState) =>
  state.check.categoriesListInEdit;

export default checkSlice.reducer;
