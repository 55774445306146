import React, { StyleHTMLAttributes } from "react";
import { Button } from "react-bootstrap";
import CardShadow from "../../../design/card/CardShadow";
import Icon from "../../../icon/Icon";

const ToolbarButton = ({
  clickAction,
  icon,
  img,
  className,
  styleBtn,
  title,
}: {
  clickAction: () => void;
  icon?: string;
  img?: string;
  className?: string;
  styleBtn?: any;
  title?: string;
}) => {
  return (
    <CardShadow
      className={`toolbar-btn ${className}`}
      style={{ backgroundColor: styleBtn ? styleBtn.backgroundColor : "" }}
      clickAction={() => clickAction()}
    >
      {icon && (
        <Icon
          icon={icon}
          size="32px"
          colorStyle={styleBtn ? styleBtn.color : "#34a381"}
          title={title}
        />
      )}
      {img && <img src={img} width="42px" title={title} />}
    </CardShadow>
  );
};

export default ToolbarButton;
