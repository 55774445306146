import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { IPin } from "../features/auth/models/Pin";
import { ICounter } from "../features/auth/data/itemHistory";
import { IBackgroundScene } from "../features/auth/fabricJs/models/BackgroundScene";
import { ILayoutGame } from "../features/auth/game/gamePlatform/models/layoutGame";

export type IThumbHistory = IPin | IBackgroundScene | ILayoutGame;
export interface HistoryItemSliceModel {
  historySelectedItem: IThumbHistory[];
  counterHistoryItem: number;
}
const initialState: HistoryItemSliceModel = {
  historySelectedItem: [],
  counterHistoryItem: -1,
};

export const historyItemSlice = createSlice({
  name: "historyItem",
  initialState,
  reducers: {
    setHistorySelectedItem: (state, action: PayloadAction<IThumbHistory>) => {
      state.historySelectedItem.push(action.payload);
    },
    revertHistorySelectedItem: (state, action: PayloadAction<number>) => {
      state.historySelectedItem = state.historySelectedItem.slice(
        0,
        action.payload
      );
    },
    resetHistorySelectedItem: (state) => {
      state.historySelectedItem = [];
    },
    setCounterHistoryItem: (state, action: PayloadAction<ICounter>) => {
      state.counterHistoryItem =
        action.payload === "next"
          ? (state.counterHistoryItem += 1)
          : (state.counterHistoryItem -= 1);
    },
    resetCounterHistoryItem: (
      state,
      action: PayloadAction<number | undefined>
    ) => {
      state.counterHistoryItem = -1 + (action.payload ? action.payload : 0);
    },
  },
});
export const {
  setHistorySelectedItem,
  revertHistorySelectedItem,
  resetHistorySelectedItem,
  setCounterHistoryItem,
  resetCounterHistoryItem,
} = historyItemSlice.actions;

export const selectHistorySelectedItem = (state: RootState) =>
  state.historyItem.historySelectedItem;

export const selectCounterHistoryItem = (state: RootState) =>
  state.historyItem.counterHistoryItem;

export default historyItemSlice.reducer;
