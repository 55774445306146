import { IUserOnline } from "./../model/userGdpr";
import http from "../../../../../../utils/http";
import { IListConfigGdpr, IPagination, IUserPagination } from "../model/config";
import { IListUserManagement } from "../model/gdpr";

const urlgdpr = "/gdpr";

export const getConfigurationGdpr = ({
  page = 0,
  size = 10,
  sort = "DESC",
}: IPagination): Promise<IListConfigGdpr> =>
  http.get(
    `${urlgdpr}/configuration/page/?page=${page}&properties=startDate&size=${size}&sort=${sort}`
  );

export const getUserManagementGdpr = ({
  userId = "",
  page = 0,
  size = 5,
  sort = "DESC",
}: IUserPagination): Promise<IListUserManagement> =>
  http.get(
    `${urlgdpr}/page/?page=${page}&properties=date&size=${size}&sort=${sort}&userId=${userId}`
  );

export const getUserOnlineGdpr = ({
  userId,
}: {
  userId: string;
}): Promise<IUserOnline> => http.get(`${urlgdpr}/gdpronlinebyuserid/${userId}`);

export const setUserToGdpr = ({
  userId,
}: {
  userId: string;
}): Promise<IUserOnline> => http.post(`${urlgdpr}/insert/`, { userId });

export const restoreUserOnline = ({
  userId,
}: {
  userId: string;
}): Promise<true | false> => http.post(`${urlgdpr}/restore/`, { userId });

export const executeConfigurationGdpr = (): Promise<any> =>
  http.get(`${urlgdpr}/configuration/execute`);

export const addConfigurationGdpr = (data: {
  day: number;
  month: number;
  startAt00: boolean;
  year: number;
}): Promise<IListConfigGdpr> => http.post(`${urlgdpr}/configuration/add`, data);
