import { IBtnList } from "../../../app/auth/model/BtnList";
import { ISelectType } from "../../../app/auth/model/FormTypes";
import { ITabs } from "../../../components/shared/design/tabs/models/tabsModels";
import { docType, videoType } from "../data/typeDocumentData";
import { IBackgroundResource } from "./model/backgroundResource";

export const listTabNameResource = {
  background: "background",
  resource: "resource",
  check: "check",
};

export const listTabsResource: ITabs[] = [
  {
    keyTab: listTabNameResource.background,
    title: "Background",
  },
  {
    keyTab: listTabNameResource.resource,
    title: "Risorse",
  },
  {
    keyTab: listTabNameResource.check,
    title: "Check",
  },
];

export const resourceToolbarBtn: IBtnList[] = [
  {
    label: "Delete",
    icon: "delete",
    color: "#34a381",
    action: "delete",
  },
  {
    label: "Save",
    icon: "save",
    color: "#34a381",
    action: "save",
  },
];

export const initListBgResourceAvailable: IBackgroundResource[] = [
  {
    url: "https://images2.alphacoders.com/902/thumb-1920-902946.png",
    type: "image/png",
    name: "sfondo1",
    initialHeight: 1080,
    initialWidth: 1920,
  },
  {
    url: "https://i.pinimg.com/originals/f3/06/89/f30689a90e172e95a277af16ad98b9c6.jpg",
    type: "image/png",
    name: "sfondo2",
    initialHeight: 1280,
    initialWidth: 2048,
  },
];

export const resourceLinkTarget: ISelectType[] = [
  {
    label: "APP.FORM.CHOOSEOPTION",
    value: "-1",
  },
  {
    label: "RESOURCE.OPENSELF",
    value: "_self",
  },
  {
    label: "RESOURCE.OPENBLANK",
    value: "_blank",
  },
];

export const resourceLanguageId: ISelectType[] = [
  {
    label: "APP.FORM.CHOOSEOPTION",
    value: "-1",
  },
  {
    label: "RESOURCE.IT",
    value: "IT",
  },
  {
    label: "RESOURCE.EN",
    value: "EN",
  },
];

export const resourceAccessOption: ISelectType[] = [
  {
    label: "APP.FORM.CHOOSEOPTION",
    value: "-1",
  },
  {
    label: "RESOURCE.DIRECT",
    value: "direct",
  },
  {
    label: "RESOURCE.S3",
    value: "s3",
  },
];

export const resourceTypeOption: ISelectType[] = [
  {
    label: "APP.FORM.CHOOSEOPTION",
    value: "-1",
  },
  {
    label: "RESOURCE.DOCTYPE",
    value: docType,
  },
  {
    label: "RESOURCE.VIDEOTYPE",
    value: videoType,
  },
];

export const languageId: string = "languageId";
export const subCategory: string = "subCategory";
export const subCategoryId: string = "subCategory___id";
