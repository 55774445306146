import React from "react";
import { useTranslation } from "react-i18next";

interface IBadgeTabs {
  changeTabsBadge: (tab: string) => void;
  tab: string;
}
const BadgeTabs = (props: IBadgeTabs) => {
  const { changeTabsBadge, tab } = props;
  const { t } = useTranslation();

  return (
    <>
      <div className="tabs-badge-container">
        <div
          className={`tabs-badge ${tab === "active" ? "selected" : ""}`}
          onClick={() => changeTabsBadge("active")}
        >
          {t("MYPROGRESS.BADGE.ACTIVEBADGES")}
        </div>
        <div
          className={`tabs-badge ${tab === "expired" ? "selected" : ""}`}
          onClick={() => changeTabsBadge("expired")}
        >
          {t("MYPROGRESS.BADGE.EXPIREDBADGES")}
        </div>
      </div>
    </>
  );
};

export default BadgeTabs;
