import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import authReducer from "./auth/authSlice";
import mapReducer from "../features/auth/projectMap/mapSlice";
import sceneReducer from "../features/auth/fabricJs/sceneSlice";
import toolbarReducer from "./toolbarSlice";
import categoriesReducer from "./categoriesSlice";
import positionUserReducer from "./positionUserSlice";
import historyItemReducer from "./historyItemSlice";
import microGameReducer from "../features/auth/game/microGame/microGameSlice";
import resourceReducer from "../features/auth/resources/resourceSlice";
import gamePlatformQuestionReducer from "../features/auth/game/gamePlatform/gamePlatformQuestionSlice";
import gamePlatformReducer from "../features/auth/game/gamePlatform/gamePlatformSlice";
import gameConfigurationReducer from "../features/auth/game/gamePlatform/gameConfigurationSlice";
import bgAvailableReducer from "./bgAvailableSlice";
import hyperlinkDocumentReducer from "../features/auth/resources/documentSlice";
import menuReducer from "../features/auth/menu/menuSlice";
import myprogressReducer from "../features/auth/menu/menuLevels/myProgress/myProgressSlice";
import gdprReducer from "../features/auth/menu/menuLevels/gdpr/gdprSlice";
import categoryManagementReducer from "../features/auth/menu/menuLevels/categoryManagement/categoryManagementSlice";
import userManagementReducer from "../features/auth/menu/menuLevels/userManagement/userManagementSlice";
import roleManagementReducer from "../features/auth/menu/menuLevels/rolesManagement/roleManagementSlice";
import LanguagesManagementReducer from "../features/auth/menu/menuLevels/languagesManagement/languagesManagementSlice";
import errorsReducer from "./errors/errorSlice";
import loadReducer from "../components/shared/loader/redux/loadSlice";
import modalReducer from "../components/shared/redux/modal/modalSlice";
import WallManageReducer from "../features/auth/menu/menuLevels/wallManage/WallManageSlice";
import commentsReducer from "../features/auth/comments/commentsSlice";
import menuManagerReducer from "../features/auth/menu/menuLevels/menuManager/MenuManagerSlice";
import aclManagementReducer from "../features/auth/menu/menuLevels/aclManagement/AclManagementSlice";
import checkReducer from "../features/auth/check/CheckSlice";
import checkManagementReducer from "../features/auth/menu/menuLevels/checkManagement/CheckManagementSlice";
import surveyReducer from "../features/auth/survey/SurveySlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    map: mapReducer,
    scene: sceneReducer,
    toolbar: toolbarReducer,
    categories: categoriesReducer,
    positionUser: positionUserReducer,
    historyItem: historyItemReducer,
    microGame: microGameReducer,
    resource: resourceReducer,
    hyperlinkDocument: hyperlinkDocumentReducer,
    gamePlatformQuestion: gamePlatformQuestionReducer,
    gamePlatform: gamePlatformReducer,
    gameConfiguration: gameConfigurationReducer,
    bgAvailable: bgAvailableReducer,
    menu: menuReducer,
    menuManager: menuManagerReducer,
    myProgress: myprogressReducer,
    gdpr: gdprReducer,
    wallManage: WallManageReducer,
    comments: commentsReducer,
    categoryManagement: categoryManagementReducer,
    userManagement: userManagementReducer,
    roleManagement: roleManagementReducer,
    LanguagesManagement: LanguagesManagementReducer,
    errors: errorsReducer,
    load: loadReducer,
    modal: modalReducer,
    aclManagement: aclManagementReducer,
    check: checkReducer,
    checkManagement: checkManagementReducer,
    surveyManagement: surveyReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
