import { SingleRoute } from "../utils/routes/models/routes";
import * as routeName from "./routeNameAuthType";

const routeResources: SingleRoute[] = [
  {
    path: "/categories/:id/resources",
    component: "features/auth/resources/ResourceLayout",
    name: routeName.links,
  },
  {
    path: "/categories/:id/resources/:resourceId/videos",
    component: "features/auth/resources/Video",
    name: routeName.video,
  },
  {
    path: "/categories/:id/resources/:resourceId/docs",
    component: "features/auth/resources/Documents",
    name: routeName.doc,
  },
];

export default routeResources;
