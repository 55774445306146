import { ITabs } from "../../../components/shared/design/tabs/models/tabsModels";

export const listTabNameComments = {
  background: "background",
};

export const listTabsComments: ITabs[] = [
  {
    keyTab: listTabNameComments.background,
    title: "APP.BACKGROUND",
  },
];
