const RolePermissions: { [key: string]: string[] } = {
  ROLE_ADMIN: ["editMode"],
};

export const permissionType = {
  ROLE_ADMIN: "ROLE_ADMIN",
};

export const getPermissions = (role: string[]) =>
  role.reduce(
    (perms: string[], role: string) => [
      ...perms,
      ...(RolePermissions[role] ? RolePermissions[role] : []),
    ],
    []
  );
export const issetPermissions = (
  permissionsUser: string[] = [],
  permissions: string[] = [],
  or: boolean = false
) => {
  return or
    ? issetOnePermission(permissionsUser, permissions)
    : issetAllPermission(permissionsUser, permissions);
};

const issetAllPermission = (
  permissionsUser: string[] = [],
  permissions: string[] = []
) => {
  return permissions.reduce(
    (control: boolean, perm) =>
      permissionsUser.includes(perm) ? control : false,
    true
  );
};

const issetOnePermission = (
  permissionsUser: string[] = [],
  permissions: string[] = []
) => {
  return permissions.reduce(
    (control: boolean, perm) =>
      permissionsUser.includes(perm) ? true : control,
    false
  );
};
