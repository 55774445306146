import http from "../../../utils/http";
import { RsFileUploaded } from "../projectMap/models/File";

const BASE_PATH = `/gps3/files`;

export const uploadFile = (
  file: FormData,
  params?: {
    prefix?: string;
    filename?: string;
    isPublic?: string;
    isStaticName?: string;
    pathName?: string;
  }
): Promise<RsFileUploaded> =>
  http.post(`${BASE_PATH}/_upload`, file, {
    params,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const getFiles = (params?: {
  prefix?: string;
  pathName?: string;
}): Promise<
  {
    cdn: string;
    url: string;
    documentName: string;
    isPublic: boolean;
    mimetype: string;
    originalfilename: string;
  }[]
> => http.get(`${BASE_PATH}/_list`, { params });

export const deleteFile = (pathFileName: string): Promise<any> =>
  http.delete(`${BASE_PATH}/_remove`, { params: { pathFileName } });
