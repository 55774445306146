import { IPinMap } from "./models/Pin";
import { ISelectType } from "../../../app/auth/model/FormTypes";
import { filePinMapImport } from "../data/thumbData";
import { IListIconToolbarMap } from "./models/Toolbar";

export const MAX_SIZE_IMAGE: number = 200;
export const MIN_SIZE_IMAGE: number = 34;

export const pinAttributesPrototype: IPinMap = {
  _id: "",
  image: {
    iconUrl:
      "https://2.bp.blogspot.com/-F4jszpW691M/WC91ORuwsoI/AAAAAAAAB-c/rtFnTYmQRLAdv4ThqRYj8qLoWmtEcg6egCLcB/s1600/Animated-GIF-Banana.gif",
    iconSize: [50, 50],
  },
  isAnimated: true,
  type: filePinMapImport.gif,
  color: "",
  name: "banana",
  position: {
    lat: 45.3568045656609,
    lng: 10.954052540764678,
  },
  subItemId: undefined,
  action: "",
  //acl: [""],
  maxWidth: 200,
  maxHeight: 200,
};

export const listPinAvailableMap: IPinMap[] = [
  /* {
    ...pinAttributesPrototype,
    image: {
      iconUrl:
        "https://2.bp.blogspot.com/-F4jszpW691M/WC91ORuwsoI/AAAAAAAAB-c/rtFnTYmQRLAdv4ThqRYj8qLoWmtEcg6egCLcB/s1600/Animated-GIF-Banana.gif",
      iconSize: [90, 90],
    },
  },
  {
    ...pinAttributesPrototype,
    image: {
      iconUrl:
        "https://media.itsnicethat.com/original_images/5bd846f97fa44c8da4000dd0.gif",
      iconSize: [90, 90],
    },
    name: "pizza",
  },
  {
    ...pinAttributesPrototype,
    image: {
      iconUrl: "https://media4.giphy.com/media/ZbBkuBOwthcGXvaIuk/source.gif",
      iconSize: [80, 80],
    },
    name: "panino",
  }, */
];

export const animationPin: ISelectType[] = [
  {
    label: "Seleziona animazione",
    value: "-1",
  },
  {
    label: "bounce",
    value: "bounce",
  },
  {
    label: "rotate",
    value: "rotate",
  },
  {
    label: "flash",
    value: "flash",
  },
  {
    label: "pulse",
    value: "pulse",
  },
  {
    label: "shake",
    value: "shake",
  },
  {
    label: "swing",
    value: "swing",
  },
  {
    label: "tada",
    value: "tada",
  },
  {
    label: "wobble",
    value: "wobble",
  },
];

export const listIconToolbar: IListIconToolbarMap[] = [
  {
    icon: "travel",
    action: "search",
    title: "MAP.SEARCH",
    color: "#34a381",
  },
  {
    icon: "star_black",
    action: "bookmark",
    title: "MAP.BOOKMARK",
    color: "#34a381",
  },
  /*   {
    icon: "pin_drop",
    action: "goTo",
    title: "MAP.GOTO",
    color: "#34a381",
  }, */
  {
    icon: "share_location",
    action: "centerMap",
    title: "MAP.CENTERMAP",
    color: "#34a381",
  },
];
