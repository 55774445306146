import { TFunction } from "i18next";
import { FormElementModelNew } from "../../../../../utils/forms/models/FormElementModel";
import { createStructureForm } from "../../../../../utils/forms/useStructureForm";

function useReportForm(t: TFunction, dataForm: any) {
  const dataStructure: FormElementModelNew[] = createStructureForm(
    [
      {
        typeElement: "input",
        label: t("APP.FORM.SECRET_KEY"),
        name: "secretKey",
        dataElement: {
          placeholder: t("APP.FORM.SECRET_KEY"),
          type: "text",
        },
        col: { xs: 12 },
      },
      {
        typeElement: "input",
        label: t("APP.FORM.SITE_URL"),
        name: "siteUrl",
        dataElement: {
          placeholder: t("APP.FORM.SITE_URL"),
          type: "text",
        },
        col: { xs: 12 },
      },
      {
        payload: [
          {
            resource: [
              {
                typeElement: "input",
                label: t("APP.FORM.DASHBOARD"),
                name: "dashboard",
                dataElement: {
                  placeholder: t("APP.FORM.DASHBOARD"),
                  type: "number",
                },
                col: { xs: 12 },
              },
            ],
          },
          {
            params: [],
          },
        ],
      },
      {
        typeElement: "input",
        label: t("APP.FORM.EXPIRED"),
        name: "expired",
        dataElement: {
          placeholder: t("APP.FORM.EXPIRED"),
          type: "number",
        },
        col: { xs: 12 },
      },
      {
        dimension: [
          {
            typeElement: "input",
            label: t("APP.FORM.WIDTH"),
            name: "width",
            dataElement: {
              placeholder: t("APP.FORM.WIDTH"),
              type: "text",
            },
            col: { xs: 4 },
          },
          {
            typeElement: "input",
            label: t("APP.FORM.HEIGHT"),
            name: "height",
            dataElement: {
              placeholder: t("APP.FORM.HEIGHT"),
              type: "text",
            },
            col: { xs: 4 },
          },
          {
            typeElement: "input",
            label: t("APP.FORM.FRAME_BORDER"),
            name: "frameborder",
            dataElement: {
              placeholder: t("APP.FORM.FRAME_BORDER"),
              type: "text",
            },
            col: { xs: 4 },
          },
        ],
      },
    ],
    dataForm
  );
  return { dataStructure };
}

export default useReportForm;
