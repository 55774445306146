import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { toolbarInit } from "../features/auth/projectMap/components/ToolbarData";
import { IToolbarContentVisible } from "../features/auth/projectMap/models/Toolbar";

export interface ToolbarSliceModel {
  toolbarContentVisible: IToolbarContentVisible;
}
const initialState: ToolbarSliceModel = {
  toolbarContentVisible: toolbarInit,
};

export const toolbarSlice = createSlice({
  name: "toolbar",
  initialState,
  reducers: {
    toggleToolbarContentVisible: (state, action: PayloadAction<string>) => {
      if (state.toolbarContentVisible[action.payload] === false)
        state.toolbarContentVisible = toolbarInit;
      state.toolbarContentVisible = {
        ...state.toolbarContentVisible,
        [action.payload]: !state.toolbarContentVisible[action.payload],
      };
    },
  },
});
export const { toggleToolbarContentVisible } = toolbarSlice.actions;

export const selectToolbarContentVisible = (state: RootState) =>
  state.toolbar.toolbarContentVisible;

export default toolbarSlice.reducer;
