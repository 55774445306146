import authRoutes from "../../routes/auth";
import publicRoutes from "../../routes/public";
import { SingleRoute } from "../../utils/routes/models/routes";

const authRouteCalc = {
  list: authRoutes.list.reduce(
    (arr: SingleRoute[], routeGroup) => [
      ...arr,
      ...routeGroup.childrenRoute.map((ele) => ({
        ...ele,
        path: routeGroup.path + ele.path,
      })),
    ],
    []
  ),
  redirect: authRoutes.redirect,
};
export const routesList = [...authRouteCalc.list, ...publicRoutes.list];
