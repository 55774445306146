import React, { useEffect, useState } from "react";
import "../../../../config/i18nPublic";
import { seti18nPublic } from "../../../../config/i18nPublic";
import PublicRoute from "../../../routes/PublicRoute";

function AutoLogin() {
  const [loadi18nPublic, setLoadI18nPublic] = useState(false);

  useEffect(() => {
    seti18nPublic(setLoadI18nPublic);
  }, []);

  return <>{loadi18nPublic && <PublicRoute isLogged={false} />}</>;
}
export default AutoLogin;
