import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { errorType } from "../../components/shared/design/errors/toast/models/ToastProps";
import {
  AppThunkError,
  ErrorModelState,
  ErrorState,
  ErrorStateWithoutd,
  InitialStateErrors,
} from "./ErrorModelState";

const initialState: InitialStateErrors = {
  errors: [],
};

export const errorSlice = createSlice({
  name: "errors",
  initialState,
  reducers: {
    addError: (state, action: PayloadAction<ErrorStateWithoutd>) => {
      state.errors.push(getErrorObj(action.payload));
    },
    setAddSuccessMessage: (state, action: PayloadAction<ErrorState>) => {
      state.errors.push(action.payload);
    },
    addWarningMessage: (state, action: PayloadAction<ErrorStateWithoutd>) => {
      state.errors.push(getErrorObj(action.payload, "warning"));
    },
    setAddErrorMessage: (state, action: PayloadAction<ErrorState>) => {
      state.errors.push(action.payload);
    },
    addUnauthorizedErrorMessage: (
      state,
      action: PayloadAction<ErrorStateWithoutd>
    ) => {
      if (!state.errors.find((el) => el.type === "unauthorized"))
        state.errors.push(getErrorObj(action.payload, "unauthorized"));
    },
    resetErrors: (state) => {
      state.errors = [];
    },
    removeError: (state, action: PayloadAction<number | undefined>) => {
      state.errors = state.errors.filter((el) => el.id !== action.payload);
    },
  },
});

export const {
  addError,
  resetErrors,
  removeError,
  setAddSuccessMessage,
  setAddErrorMessage,
  addWarningMessage,
  addUnauthorizedErrorMessage,
} = errorSlice.actions;

export const getErrorObj = (error: ErrorStateWithoutd, type?: errorType) => {
  const id: number = Date.now() * Math.round(Math.random() * 100);
  return { ...error, id, type };
};

export const addSuccessMessage =
  (error: ErrorStateWithoutd): AppThunkError =>
  (dispatch) => {
    const obj: ErrorState = getErrorObj(error, "success");
    dispatch(setAddSuccessMessage(obj));
    setTimeout(() => {
      dispatch(removeError(obj.id));
    }, 3000);
  };
export const addErrorMessage =
  (error: ErrorStateWithoutd): AppThunkError =>
  (dispatch) => {
    const obj: ErrorState = getErrorObj(error, "error");
    dispatch(setAddErrorMessage(obj));
    setTimeout(() => {
      dispatch(removeError(obj.id));
    }, 4000);
  };

export const selectErrors = (state: ErrorModelState) => state.errors.errors;

export default errorSlice.reducer;
