import { ITabs } from "../../../../../components/shared/design/tabs/models/tabsModels";

export const tabsNameProgress = {
  active: "active",
  expired: "expired",
};

export const listTabsProgress: ITabs[] = [
  {
    keyTab: tabsNameProgress.active,
    title: "MYPROGRESS.BADGE.ACTIVEBADGES",
  },
  {
    keyTab: tabsNameProgress.expired,
    title: "MYPROGRESS.BADGE.EXPIREDBADGES",
  },
];

export const cardTitleProgress = {
  active: "MYPROGRESS.BADGE.BADGEPASSACTIVE",
  expired: "MYPROGRESS.BADGE.EXPIREDBADGES",
};
