import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  addErrorMessage,
  addSuccessMessage,
} from "../../../../../app/errors/errorSlice";
import { AppThunk, RootState } from "../../../../../app/store";
import i18n from "../../../../../config/i18n";
import { IListConfigGdpr } from "./model/config";
import { IContent, IListUserManagement } from "./model/gdpr";
import { IUserOnline } from "./model/userGdpr";
import {
  addConfigurationGdpr,
  executeConfigurationGdpr,
  getConfigurationGdpr,
  getUserManagementGdpr,
  getUserOnlineGdpr,
  restoreUserOnline,
  setUserToGdpr,
} from "./services/gdpr.service";

export interface IGdprSlice {
  listConfiguration: IListConfigGdpr | undefined;
  listUserManagement: IListUserManagement | undefined;
  userOnline: IUserOnline | undefined;
  userGdpr: IUserOnline | undefined;
}
const initialState: IGdprSlice = {
  listConfiguration: undefined,
  listUserManagement: undefined,
  userOnline: undefined,
  userGdpr: undefined,
};

export const gdprSlice = createSlice({
  name: "gdpr",
  initialState,
  reducers: {
    setListConfiguration: (state, action: PayloadAction<IListConfigGdpr>) => {
      state.listConfiguration = action.payload;
    },
    setListUserManagement: (
      state,
      action: PayloadAction<IListUserManagement>
    ) => {
      state.listUserManagement = action.payload;
    },
    resetListUserManagement: (state) => {
      state.listUserManagement = undefined;
    },
    setUserOnline: (state, action: PayloadAction<IUserOnline>) => {
      state.userOnline = action.payload;
    },
    resetUserOnline: (state) => {
      state.userOnline = undefined;
    },
    setUserGdpr: (state, action: PayloadAction<IUserOnline>) => {
      state.userGdpr = action.payload;
    },
    resetUserGdpr: (state) => {
      state.userGdpr = undefined;
    },
  },
});

export const {
  setListConfiguration,
  setListUserManagement,
  resetListUserManagement,
  setUserOnline,
  resetUserOnline,
  setUserGdpr,
  resetUserGdpr,
} = gdprSlice.actions;

export const getGdprConfiguration =
  ({
    page = 0,
    size = 10,
    sort = "DESC",
  }: {
    page?: number;
    size?: number;
    sort?: string;
  }): AppThunk =>
  (dispatch) => {
    getConfigurationGdpr({ page, size, sort }).then(
      (res) => {
        dispatch(setListConfiguration(res));
      },
      (err) =>
        dispatch(
          addErrorMessage({
            title: i18n.t("ERRORSMSG.LOADINGCONFIGGDPR"),
          })
        )
    );
  };

export const getListUserManagement =
  ({
    userId,
    page = 0,
    size = 5,
    sort = "DESC",
  }: {
    userId?: string;
    page?: number;
    size?: number;
    sort?: string;
  }): AppThunk =>
  (dispatch) => {
    getUserManagementGdpr({ userId, page, size, sort }).then(
      (res) => dispatch(setListUserManagement(res)),
      (err) =>
        dispatch(
          addErrorMessage({
            title: i18n.t("ERRORSMSG.SEARCHUSER"),
          })
        )
    );
  };
export const getUserOnline =
  ({ userId }: { userId: string }): AppThunk =>
  (dispatch) => {
    getUserOnlineGdpr({ userId }).then(
      (res) => dispatch(setUserOnline(res)),
      (err) =>
        dispatch(
          addErrorMessage({
            title: i18n.t("ERRORSMSG.SEARCHUSER"),
          })
        )
    );
  };
export const setUserToGdprAction =
  ({ userId }: { userId: string }): AppThunk =>
  (dispatch) => {
    setUserToGdpr({ userId }).then(
      (res) => {
        dispatch(
          addSuccessMessage({
            title: i18n.t("SUCCESSMSG.ADDUSERINGDPR"),
          })
        );
      },
      (err) =>
        dispatch(
          addErrorMessage({
            title: i18n.t("ERRORSMSG.ADDUSERINGDPR"),
          })
        )
    );
  };
export const restoreUserOnlineAction =
  ({ userId }: { userId: string }): AppThunk =>
  (dispatch, getState) => {
    restoreUserOnline({ userId }).then(
      (res) => {
        if (res) {
          dispatch(
            addSuccessMessage({
              title: i18n.t("SUCCESSMSG.RESTOREUSERINGDPR"),
            })
          );
          const oldUserMng = getState().gdpr.listUserManagement;
          const newGdprUserList = oldUserMng?.content.filter(
            (ele: IContent) => ele.id !== userId
          );
          newGdprUserList &&
            newGdprUserList.length &&
            dispatch(
              setListUserManagement({
                ...oldUserMng,
                content: newGdprUserList,
              } as IListUserManagement)
            );
        } else {
          dispatch(
            addErrorMessage({
              title: i18n.t("ERRORSMSG.RESTOREUSERINGDPR"),
            })
          );
        }
      },
      (err) => {
        dispatch(
          addErrorMessage({
            title: i18n.t("ERRORSMSG.RESTOREUSERINGDPR"),
          })
        );
      }
    );
  };

export const runConfigurationGdpr = (): AppThunk => (dispatch) => {
  executeConfigurationGdpr().then(
    (res) => {
      dispatch(
        addSuccessMessage({
          title: i18n.t("SUCCESSMSG.RUNCONFIG"),
        })
      );
    },
    (err) =>
      dispatch(
        addErrorMessage({
          title: i18n.t("ERRORSMSG.RUNCONFIG"),
        })
      )
  );
};
export const insertConfigurationGdpr =
  (data: any): AppThunk =>
  (dispatch, getState) => {
    addConfigurationGdpr(data).then(
      (res) => {
        dispatch(getGdprConfiguration({}));
        dispatch(
          addSuccessMessage({
            title: i18n.t("SUCCESSMSG.INSERTCONFIG"),
          })
        );
      },
      (err) =>
        dispatch(
          addErrorMessage({
            title: i18n.t("ERRORSMSG.INSERTCONFIG"),
          })
        )
    );
  };
export const selectListConfiguration = (state: RootState) =>
  state.gdpr.listConfiguration;
export const selectListUserManagement = (state: RootState) =>
  state.gdpr.listUserManagement;
export const selectUserOnline = (state: RootState) => state.gdpr.userOnline;
export const selectUserGdpr = (state: RootState) => state.gdpr.userGdpr;

export default gdprSlice.reducer;
