import omit from "lodash.omit";
import http from "../../../../utils/http";
import { ICheckItem } from "../../menu/menuLevels/checkManagement/model/config";
import { ICheckCreateResponse } from "../model/config";

const ACL = `/acl`;

export const createCheck = (data: ICheckItem): Promise<ICheckCreateResponse> =>
  http.post(`${ACL}/definition`, data);

export const getCheckDefinitionById = (id: string): Promise<ICheckItem> =>
  http.get(`${ACL}/definition/${id}`);

export const updateCheckById = (
  id: string,
  data: ICheckItem
): Promise<ICheckCreateResponse> => {
  let newData = omit(data, ["_id", "__v", "id"]);
  return http.put(`${ACL}/definition/${id}`, newData);
};

export const getCheckListByName = (nameToFind: string): Promise<ICheckItem[]> =>
  http.get(`${ACL}/definition/?name=${nameToFind}`);

export const removeCheck = (idCheckToRemove: string): Promise<any> =>
  http.delete(`${ACL}/definition/${idCheckToRemove}`);
