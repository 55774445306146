import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ModalPropsBtn } from "../../modal/models/ModalProps";
import modalEvents from "./modalEvent";
import {
  AppThunkModal,
  ModalInitialState,
  ModalSizeModel,
  ModalStateModel,
  ModalTextModel,
} from "./model/ModalStateModel";

const initialState: ModalInitialState = {
  show: false,
  btnList: [],
  text: {},
  size: undefined,
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setShow: (state) => {
      state.show = true;
    },
    setHide: (state) => {
      state.show = false;
    },
    setBtnList: (state, action: PayloadAction<ModalPropsBtn[]>) => {
      state.btnList = action.payload;
    },
    setTitle: (state, action: PayloadAction<string>) => {
      state.text = { ...state.text, title: action.payload };
    },
    setBody: (state, action: PayloadAction<string>) => {
      state.text = { ...state.text, body: action.payload };
    },
    setText: (state, action: PayloadAction<ModalTextModel>) => {
      state.text = action.payload;
    },
    setSize: (state, action: PayloadAction<ModalSizeModel>) => {
      state.size = action.payload;
    },
  },
});

export const {
  setShow,
  setHide,
  setBtnList,
  setTitle,
  setBody,
  setText,
  setSize,
} = modalSlice.actions;

export const setModal =
  (
    btnList: ModalPropsBtn[],
    text: { title?: string; body?: string; templateBody?: string },
    events: { [key: string]: () => void },
    size?: "sm" | "lg" | "xl" | undefined
  ): AppThunkModal =>
  (dispatch, getState) => {
    dispatch(resetModal());
    dispatch(setShow());
    dispatch(setBtnList(btnList));
    dispatch(setText(text));
    dispatch(setSize(size));
    modalEvents.events = events;
  };

export const resetModal = (): AppThunkModal => (dispatch, getState) => {
  modalEvents.events = {};
  dispatch(setHide());
  dispatch(setBtnList([]));
  dispatch(setText({}));
  dispatch(setSize(undefined));
};

export const dispatchEventModal = (action: string = "") => {
  modalEvents.events && modalEvents.events[action]
    ? modalEvents.events[action]()
    : console.log(`${action} evento non presente`);
};

export const selectShow = (state: ModalStateModel) => !!state.modal.show;
export const selectBtnList = (state: ModalStateModel) => state.modal.btnList;
export const selectText = (state: ModalStateModel) => state.modal.text;
export const selectTemplateBody = (state: ModalStateModel) =>
  state.modal.text.templateBody;
export const selectSize = (state: ModalStateModel) => state.modal.size;
export default modalSlice.reducer;
