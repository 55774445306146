import { gameQuestionData } from "../microGame/gameQuestionSample";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "../../../../app/store";
import { IDragDropAnswer, ISideBar, IToolbarInfo } from "./models/gameQuestion";
import {
  IAnswerMessageResponse,
  IAttemptResultResponse,
} from "./models/servicesResponse";
import {
  ILoadDataGameResponse,
  INextQuestionGame,
} from "./models/servicesResponse";
import {
  getAttemptResult,
  getLoadDataGame,
  getNextQuestionGame,
  getQuestionAnswer,
  postQuestionAnswer,
} from "./services/gamePlatform.service";
import { setIsGameStarted } from "./gamePlatformSlice";
import {
  dragndrop,
  multichoice,
  singlechoice,
  survey,
  survey_multichoice,
} from "../../data/gameData";

export interface IGamePlatformQuestionSlice {
  gameLoadInfo?: ILoadDataGameResponse;
  toolbarInfo?: IToolbarInfo;
  sidebarGame?: ISideBar;
  gameQuestion?: INextQuestionGame;
  gameMultipleAnswer: string[];
  gameDragDropAnswer: IDragDropAnswer[];
  isQuestionAnswered: boolean;
  answerMessage?: IAnswerMessageResponse;
  isComic: boolean;
  isComicQuestion: boolean;
  attemptResult?: IAttemptResultResponse;
  gameId: string | undefined;
  startCountdown: boolean;
  attemptCompleted: boolean;
}
const initialState: IGamePlatformQuestionSlice = {
  gameLoadInfo: undefined,
  toolbarInfo: undefined,
  sidebarGame: undefined,
  gameQuestion: undefined,
  gameMultipleAnswer: [],
  gameDragDropAnswer: [],
  isQuestionAnswered: false,
  answerMessage: undefined,
  isComic: gameQuestionData.carousels.length > 0,
  isComicQuestion: false,
  attemptResult: undefined,
  gameId: undefined,
  startCountdown: false,
  attemptCompleted: false,
};

export const gamePlatformQuestionSlice = createSlice({
  name: "gamePlatformQuestion",
  initialState,
  reducers: {
    setGameLoadInfo: (state, action: PayloadAction<ILoadDataGameResponse>) => {
      state.gameLoadInfo = action.payload;
    },
    setToolbarInfo: (state, action: PayloadAction<IToolbarInfo>) => {
      state.toolbarInfo = action.payload;
    },
    resetToolbarInfo: (state) => {
      state.toolbarInfo = undefined;
    },
    setSidebarGame: (state, action: PayloadAction<ISideBar>) => {
      state.sidebarGame = action.payload;
    },
    resetGameLoadInfo: (state) => {
      state.gameLoadInfo = undefined;
    },
    resetSidebarGame: (state) => {
      state.sidebarGame = undefined;
    },
    setGameQuestion: (state, action: PayloadAction<INextQuestionGame>) => {
      state.gameQuestion = action.payload;
    },
    resetGameQuestion: (state) => {
      state.gameQuestion = undefined;
    },
    setGameMultipleAnswer: (state, action: PayloadAction<string[]>) => {
      state.gameMultipleAnswer = action.payload;
    },
    resetGameMultipleAnswer: (state) => {
      state.gameMultipleAnswer = [];
    },
    setGameDragDropAnswer: (
      state,
      action: PayloadAction<IDragDropAnswer[]>
    ) => {
      state.gameDragDropAnswer = action.payload;
    },
    resetGameDragDropAnswer: (state) => {
      state.gameDragDropAnswer = [];
    },
    setIsQuestionAnswered: (state, action: PayloadAction<boolean>) => {
      state.isQuestionAnswered = action.payload;
    },
    setAnswerMessage: (
      state,
      action: PayloadAction<IAnswerMessageResponse>
    ) => {
      state.answerMessage = action.payload;
    },
    resetAnswerMessage: (state) => {
      state.answerMessage = undefined;
    },
    setIsComic: (state, action: PayloadAction<boolean>) => {
      state.isComic = action.payload;
    },
    setIsComicQuestion: (state, action: PayloadAction<boolean>) => {
      state.isComicQuestion = action.payload;
    },
    setAttemptResult: (
      state,
      action: PayloadAction<IAttemptResultResponse>
    ) => {
      state.attemptResult = action.payload;
    },
    resetAttemptResult: (state) => {
      state.attemptResult = undefined;
    },
    setGameId: (state, action: PayloadAction<string>) => {
      state.gameId = action.payload;
    },
    resetGameId: (state) => {
      state.gameId = undefined;
    },
    setStartCountdown: (state, action: PayloadAction<boolean>) => {
      state.startCountdown = action.payload;
    },
    setAttemptCompleted: (state, action: PayloadAction<boolean>) => {
      state.attemptCompleted = action.payload;
    },
  },
});
export const {
  setGameLoadInfo,
  setToolbarInfo,
  resetGameLoadInfo,
  resetToolbarInfo,
  setSidebarGame,
  resetSidebarGame,
  setGameQuestion,
  resetGameQuestion,
  setGameMultipleAnswer,
  resetGameMultipleAnswer,
  resetGameDragDropAnswer,
  setIsQuestionAnswered,
  setAnswerMessage,
  resetAnswerMessage,
  setIsComic,
  setIsComicQuestion,
  setGameDragDropAnswer,
  setAttemptResult,
  resetAttemptResult,
  setGameId,
  resetGameId,
  setStartCountdown,
  setAttemptCompleted,
} = gamePlatformQuestionSlice.actions;

export const getGameLoadInfo =
  (gameId: string): AppThunk =>
  (dispatch) => {
    getLoadDataGame(gameId).then((res) => {
      dispatch(setGameLoadInfo(res));
      dispatch(setToolbarInfo(res.toolbarInfo));
    });
  };

export const getGameQuestion =
  (gameId: string): AppThunk =>
  (dispatch) => {
    getNextQuestionGame(gameId).then((res) => {
      dispatch(setGameQuestion(res));
      dispatch(setToolbarInfo(res.toolbarInfo));
      dispatch(setSidebarGame(res.sideBar));
      dispatch(setIsGameStarted(true));
      res.carousels && res.carousels.length > 0 && dispatch(setIsComic(true));
    });
  };

export const getGameAnswer =
  (
    //TODO da sistemare per drag&drop!
    gameId: string,
    failedAnswer?: boolean
  ): AppThunk =>
  (dispatch, getState) => {
    const questionType = getState().gamePlatformQuestion.gameQuestion?.gui;
    if (
      questionType === singlechoice ||
      questionType === multichoice ||
      questionType === survey ||
      questionType === survey_multichoice
    ) {
      const savedAnswers = getState().gamePlatformQuestion.gameMultipleAnswer;
      const data = failedAnswer ? "-1" : savedAnswers.join();
      getQuestionAnswer(gameId, new Date().getTime().toString(), data).then(
        (res) => {
          dispatch(setAnswerMessage(res));
          dispatch(setToolbarInfo(res.toolbarInfo));
          dispatch(setSidebarGame(res.sideBar));
          dispatch(setAttemptCompleted(res.attemptCompleted));
          dispatch(setIsQuestionAnswered(true));
        }
      );
    } else if (questionType === dragndrop) {
      const dragAndDropAnswers =
        getState().gamePlatformQuestion.gameDragDropAnswer;
      const failed = [
        {
          active: {
            active: true,
          },
          id: -1,
          items: [],
          maxElement: "",
          name: "",
        },
      ];
      const data = failedAnswer ? failed : dragAndDropAnswers;
      postQuestionAnswer(gameId, new Date().getTime().toString(), data).then(
        (res) => {
          dispatch(setAnswerMessage(res));
          dispatch(setToolbarInfo(res.toolbarInfo));
          dispatch(setSidebarGame(res.sideBar));
          dispatch(setIsQuestionAnswered(true));
          dispatch(resetGameDragDropAnswer());
        }
      );
    }
  };

export const resetGameData = (): AppThunk => (dispatch) => {
  dispatch(resetGameLoadInfo());
  dispatch(resetToolbarInfo());
  dispatch(resetSidebarGame());
  dispatch(resetGameQuestion());
  dispatch(resetGameMultipleAnswer());
  dispatch(resetGameDragDropAnswer());
  dispatch(resetAnswerMessage());
  dispatch(setIsQuestionAnswered(false));
  dispatch(setIsGameStarted(false));
  dispatch(resetAttemptResult());
  dispatch(setStartCountdown(false));
  dispatch(setAttemptCompleted(false));
};

export const gameNextQuestion =
  (gameId: string): AppThunk =>
  (dispatch) => {
    dispatch(resetGameData());
    dispatch(getGameQuestion(gameId));
  };

export const gameAttemptResult =
  (gameId: string): AppThunk =>
  (dispatch) => {
    getAttemptResult(gameId)
      .then((res) => {
        dispatch(resetGameData());
        return res;
      })
      .then((res) => {
        dispatch(setToolbarInfo(res.toolbarInfo));
        dispatch(setAttemptResult(res));
        dispatch(setIsGameStarted(true));
      });
  };

export const selectGameLoadInfo = (state: RootState) =>
  state.gamePlatformQuestion.gameLoadInfo;
export const selectToolbarInfo = (state: RootState) =>
  state.gamePlatformQuestion.toolbarInfo;
export const selectSidebarGame = (state: RootState) =>
  state.gamePlatformQuestion.sidebarGame;
export const selectGameQuestion = (state: RootState) =>
  state.gamePlatformQuestion.gameQuestion;
export const selectGameMultipleAnswer = (state: RootState) =>
  state.gamePlatformQuestion.gameMultipleAnswer;
export const selectIsQuestionAnswered = (state: RootState) =>
  state.gamePlatformQuestion.isQuestionAnswered;
export const selectAnswerMessage = (state: RootState) =>
  state.gamePlatformQuestion.answerMessage;
export const selectIsComic = (state: RootState) =>
  state.gamePlatformQuestion.isComic;
export const selectIsComicQuestion = (state: RootState) =>
  state.gamePlatformQuestion.isComicQuestion;
export const selectGameDragDropAnswer = (state: RootState) =>
  state.gamePlatformQuestion.gameDragDropAnswer;
export const selectAttemptResult = (state: RootState) =>
  state.gamePlatformQuestion.attemptResult;
export const selectGameId = (state: RootState) =>
  state.gamePlatformQuestion.gameId;
export const selectStartCountdown = (state: RootState) =>
  state.gamePlatformQuestion.startCountdown;
export const selectAttemptCompleted = (state: RootState) =>
  state.gamePlatformQuestion.attemptCompleted;

export default gamePlatformQuestionSlice.reducer;
