import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ToolbarButton from "../../../components/shared/custom/toolbar/toolbarButton/ToolbarButton";
import HeadingH3 from "../../../components/shared/headings/headingH3/HeadingH3";
import { useRoute } from "../../../utils/routes/useRoute";
import MenuItem from "./MenuItem";
import { getListMenu, selectListMenu } from "./menuSlice";
import { IMenuItem } from "./models/menuItem";

export interface IMenuLayout {
  children: React.ReactNode;
}

const MenuLayout = ({ children }: IMenuLayout) => {
  const { changeRoute } = useRoute();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getListMenu());
  }, []);
  const listMenu = useSelector(selectListMenu);
  const [toggleMenu, setToggleMenu] = useState(true);
  const [idMenuAperto, setIdMenuAperto] = useState<string>("");

  const [nameMenuItem, setNameMenuItem] = useState<string>(
    "MENU.DISCLAIMERLABEL"
  );

  useEffect(() => {
    listMenu.length > 0 && cambiaIdMenu(listMenu[0]);
    return () => {};
  }, [listMenu]);

  const cambiaIdMenu = (ele: IMenuItem) => {
    if (idMenuAperto === ele._id) {
      setIdMenuAperto("");
    } else {
      setIdMenuAperto(ele._id);
    }
    setNameMenuItem(ele.label);
    changeRoute(ele.route);
  };

  return (
    <div className="menu-shared">
      <div className="wrapper">
        <nav id="sidebar-menu" className={toggleMenu ? "" : "close"}>
          <div className="sidebar-header">
            <h3>Header menu</h3>
            <p>subtitle</p>
          </div>
          <ul className="list-unstyled components">
            <MenuItem
              itemList={listMenu}
              handleClickLevel={cambiaIdMenu}
              handleClickSubLevel={(route: string) => {
                changeRoute(route);
              }}
              idMenuAperto={idMenuAperto}
            />
          </ul>
        </nav>
        <div id="content" className={toggleMenu ? "" : "close"}>
          <div className="d-flex align-items-center">
            <ToolbarButton
              clickAction={() => setToggleMenu(!toggleMenu)}
              icon={toggleMenu ? "arrow_back" : "arrow_forward"}
              className="btn-toogle"
            />
            <HeadingH3 className="ml-3">{t(nameMenuItem)}</HeadingH3>
          </div>
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default MenuLayout;
