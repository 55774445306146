import React from "react";
import ModalMsg from "../../../../modal/ModalMsg";
import {
  ModalPropsBtn,
  ModalPropsStyle,
} from "../../../../modal/models/ModalProps";

interface ModalProps {
  className?: string;
  text: { title?: string; body?: string };
  templateBody?: React.ReactNode;
  btnList: ModalPropsBtn[];
  showModal?: boolean;
  closeButton?: boolean;
  size?: "sm" | "lg" | "xl" | undefined;
  clickAction: (action: string) => void;
}

const styleSuccess: ModalPropsStyle = {
  style: { color: "#565B6F", bgColor: "#fff" },
  icon: { color: "orange", ico: "warning" },
};
/* const btnSuccess: ModalPropsBtn[] = [
	{
		label: 'Scegli',
		action: 'scegliAction',
		type: 'primary',
		dataType: { size: 'sm' },
	},
	{
		label: 'inserisci',
		action: 'inserisciAction',
		type: 'secondary',
		dataType: { size: 'sm' },
	},
];
 */
const ModalWarning = ({
  className,
  text,
  templateBody,
  btnList,
  clickAction,
  closeButton,
  size,
  ...rest
}: ModalProps) => {
  return (
    <ModalMsg
      className="shared-modalSuccess"
      modalStyle={styleSuccess}
      btn={btnList}
      text={text}
      templateBody={templateBody}
      clickAction={clickAction}
      closeButton={closeButton}
      size={size}
      closeAction={() => {
        console.log("chiudi");
      }}
      {...rest}
    ></ModalMsg>
  );
};

export default ModalWarning;
