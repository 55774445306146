import { Routes } from "../utils/routes/models/routes";
import * as routeName from "./routeNameAuthType";

export const routes: Routes = {
  list: [
    {
      path: "/register",
      component: "features/public/register/Register",
      name: "Register",
    },
    {
      path: "/redirect",
      component: "features/public/PublicRedirect",
      name: "Redirect",
    },
  ],
  redirect: "/redirect",
};

export default routes;
