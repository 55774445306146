export interface ISelectType {
  label: string;
  value: string;
}

export enum fontStyle {
  NORMAL = "normal",
  ITALIC = "italic",
  // OBLIQUE = "oblique",
}
export enum fontWeight {
  NORMAL = "normal",
  BOLD = "italic",
}
