import { useHistory } from "react-router";
import { routes as r } from "./getRoutes";
export function useRoute() {
  const history = useHistory();
  // const routes = r.routes;
  const changeRoute = (name: string, data?: { [key: string]: string }) => {
    if (name.startsWith("/")) {
      history.push(name);
      return;
    }
    let path = getRoute(name);
    if (path.includes(":")) {
      data &&
        Object.keys(data).forEach((ele) => {
          path = path.replace(":" + ele, data[ele]);
        });
    }
    history.push(path);
  };
  const findRoute = (name: string) => {
    return r.routes.find((ele) => ele.name === name);
  };
  const getRoute = (name: string): string => {
    const rt = findRoute(name);
    return rt ? rt.path : "/";
  };

  const goBack = (): void => {
    history.goBack();
  };

  const checkActiveLink = (route: string) => {
    const actualRoute = history.location.pathname.split("/").slice(-1)[0];
    return actualRoute === route;
  };

  return {
    changeRoute,
    goBack,
    checkActiveLink,
  };
}
