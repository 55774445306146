import axios from "axios";
import { getAccessToken } from "./LocalStorageService";
import { TokenResponse } from "./models/TokenResponse";
import { WellKnownProfile } from "./wellKnown";

const urlRegex = /(?:https?):\/\/(?<hostname>(?:[a-zA-Z.]+)[a-zA-Z0-9\-.]+\.(?:[a-z]{0,4}))?(?::)?(?<port>[0-9]{0,4}).+/;
//use this to allow also localhost
//const urlRegex = /(?:https|http?):\/\/(?<hostname>(?:[a-zA-Z.]+)[a-zA-Z0-9\-.]?(?:\.)?(?:[a-z]{0,4}))?(?::)?(?<port>[0-9]{0,4}).+/;
export function interceptor(
  realm: string,
  refreshToken: () => Promise<TokenResponse>
) {
  axios.interceptors.request.use(
    (config) => {
      const token = getAccessToken();
      if (
        token &&
        matchHostname(process.env.REACT_APP_URL || "", config.url || "")
      ) {
        config.headers["Authorization"] = `Bearer ${token}`;
        //config.headers["x-realm-name"] = realm;
        config.headers = {
          ...config.headers,
          //...WellKnownProfile.getData().metadata?.endpoint_api_headers,
        };
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const originalRequest = error.config;
      // to do massy
      // error?.response?.status === 403 &&
      //   store.dispatch(
      //     addUnauthorizedErrorMessage({
      //       title: i18n.t("errors.fe.noPermission"),
      //     })
      //   );
      if (
        (error?.response?.status === 401 || error?.response?.status === 403) &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
        return refreshToken()
          .then((res) => {
            axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${res.access_token}`;
            return axios(originalRequest);
          })
          .catch((error) => Promise.reject(error));
      }
      return Promise.reject(error);
    }
  );
}

function matchHostname(u1: string, u2: string) {
  if (u1 && u2) {
    const h1 = new URL(u1).hostname;
    const h2 = new URL(u2).hostname;
    return h1 === h2;
  }
  return false;
}
