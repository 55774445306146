import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.scss";
import ErrorManagement from "./app/errors/ErrorManagement";
import AppLayout from "./components/core/app-layout/AppLayout";
import AuthenticationProvider from "./components/core/auth/Authentication";
import Anonymous from "./components/core/auth/components/Anonymous";
import AutoLogin from "./components/core/auth/components/AutoLogin";
import LoggedIn from "./components/core/auth/components/LoggedIn";
import Public from "./components/core/auth/components/Public";
import useWellKnown from "./components/core/auth/wellKnown";
import AuthRoute from "./components/routes/AuthRoute";
import LoaderFullScreen from "./components/shared/loader/loaderFullscreen/LoaderFullScreen";
import ModalApp from "./components/shared/redux/modal/ModalApp";

function App() {
  const wellKnown = useWellKnown();
  return (
    <>
      {wellKnown && (
        <AuthenticationProvider options={wellKnown}>
          <Router>
            <LoaderFullScreen />
            <ErrorManagement />
            <ModalApp />

            <Public>
              <AutoLogin />
            </Public>
            <LoggedIn>
              <AppLayout>
                <AuthRoute />
              </AppLayout>
            </LoggedIn>
          </Router>
          <Anonymous>
            <h2>Loading...</h2>
          </Anonymous>
        </AuthenticationProvider>
      )}
    </>
  );
}

export default App;
