import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { addErrorMessage } from "../../../app/errors/errorSlice";
import { AppThunk, RootState } from "../../../app/store";
import i18n from "../../../config/i18n";
import { IQuestionSurvey, ISaveAnswer, ISurvey } from "./model/config";
import { getSurveyInfo } from "./services/survey.service";
import { useSurveyQuestions } from "./UseSurveyQuestions";

interface ISurveySlice {
  survey?: ISurvey;
}

const initialState: ISurveySlice = {
  survey: undefined,
};

export const surveySlice = createSlice({
  name: "surveyManagement",
  initialState,
  reducers: {
    setSurvey: (state, action: PayloadAction<ISurvey>) => {
      state.survey = action.payload;
    },
    resetSurvey: (state) => {
      state.survey = undefined;
    },
  },
});

export const { setSurvey, resetSurvey } = surveySlice.actions;

export const getSurvey = (): AppThunk => (dispatch) => {
  // getSurveyInfo().then(
  //   (res) => {
  // dispatch(setSurvey(res));
  dispatch(setSurvey(useSurveyQuestions));
  //   },
  //   (err) =>
  //     dispatch(
  //       addErrorMessage({
  //         title: i18n.t("ERRORS.LOADSURVEY"),
  //       })
  //     )
  // );
};

export const saveAnswer =
  ({ value, progressive }: ISaveAnswer): AppThunk =>
  (dispatch, getState) => {
    let survey = getState().surveyManagement.survey;
    if (survey) {
      let selectedQuestion = [
        ...survey.questions.filter(
          (question: IQuestionSurvey) => question.progressive === progressive
        ),
      ];
      if (selectedQuestion && selectedQuestion?.length > 0) {
        let accepted = [
          ...selectedQuestion[0].accepted.filter(
            (acceptedValue: string) => acceptedValue !== value
          ),
          value,
        ];
        let discarder = [
          ...selectedQuestion[0].discarder.filter(
            (discardedValue: string) => discardedValue !== value
          ),
        ];
        let sq = {
          ...selectedQuestion[0],
          accepted: accepted,
          discarder: discarder,
        };
        let newQuestions: any = [
          ...survey.questions.filter(
            (question: IQuestionSurvey) => question.progressive !== progressive
          ),
          sq,
        ];
        newQuestions.sort((a: any, b: any) => {
          return a.progressive - b.progressive;
        });
        let newSurvey = { ...survey, questions: newQuestions };
        dispatch(setSurvey(newSurvey));
      }
    }
  };

export const selectSurvey = (state: RootState) => state.surveyManagement.survey;

export default surveySlice.reducer;
