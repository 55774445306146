import React from "react";
import { useSelector } from "react-redux";
import LoaderFullScreen from "../../components/shared/loader/loaderFullscreen/LoaderFullScreen";
import { selectActiveLoad } from "../../components/shared/loader/redux/loadSlice";

const LoaderFullScreenApp = () => {
  const show = useSelector(selectActiveLoad);
  return <LoaderFullScreen show={show} />;
};
export default LoaderFullScreenApp;
