import React, { useEffect } from "react";
import { FormCheck } from "react-bootstrap";
import { mergeClass } from "../../../functions/function";
import Label from "../label/Label";
import { switchType } from "../../../../../utils/forms/models/FormElementModel";

const Switch: React.FC<switchType> = ({
  error = false,
  label = "",
  checked,
  name,
  className,
  typeCheck,
  noRequired,
  changeSwitch,
  ...rest
}) => {
  const changeValue = (event: any) => {
    changeSwitch(
      typeCheck === "checkbox" ? event.target.checked : event.target.value
    );
  };

  useEffect(() => {
    if (checked === undefined && !noRequired) {
      changeSwitch(false);
    }
  });

  return (
    <>
      <div className={mergeClass("d-flex align-items-end", className)}>
        <FormCheck
          className={`shared-inputSwitch mr-4 ${error ? "error" : ""}`}
          type="switch"
          id={"custom-switch-" + Math.random().toString(36).substr(2, 5)}
          name={name}
          label=""
          checked={checked}
          onChange={changeValue}
          {...rest}
        />
        {label ? <Label label={label}></Label> : null}
      </div>
    </>
  );
};

export default Switch;
