import React, { ReactNode } from "react";
import { templateRowModel } from "./models/FormElementModel";

const FormElementsTemplateRow = ({
  children,
  templateRow,
  removeAction,
  values,
}: {
  children: ReactNode;
  templateRow?: templateRowModel;
  values?: any;
  removeAction?: () => void;
}) => {
  return (
    <>{templateRow ? templateRow(children, removeAction, values) : children}</>
  );
};

export default FormElementsTemplateRow;
