import React from "react";
import Icon from "../../../../icon/Icon";

interface StateLabelProps extends React.HTMLAttributes<HTMLOrSVGElement> {
  icon?: string;
  size?: string;
}

const IconAction = ({
  children,
  icon = "edit",
  size = "18px",
  className,
  ...rest
}: StateLabelProps) => {
  return (
    <div className="custom iconAction" {...rest}>
      <Icon icon={icon} size={size}></Icon>
    </div>
  );
};

export default IconAction;
