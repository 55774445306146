import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { addErrorMessage } from "../../../app/errors/errorSlice";
import { AppThunk, RootState } from "../../../app/store";
import i18n from "../../../config/i18n";
import { getHyperlinks, getHyperlinksByCdn } from "./services/document.service";
import { getHyperlinkByCategoryType } from "./services/resources.service";

export interface HyperlinkDocumentSlice {
  hyperlinkList: any[];
  selectedHyperlink: any;
}

const initialState: HyperlinkDocumentSlice = {
  hyperlinkList: [],
  selectedHyperlink: undefined,
};

export const hyperlinkDocumentSlice = createSlice({
  name: "hyperlinkDocument",
  initialState,
  reducers: {
    setHyperlinkList: (state, action: PayloadAction<any[]>) => {
      state.hyperlinkList = action.payload;
    },
    setSelectedHyperlink: (state, action: PayloadAction<any>) => {
      state.selectedHyperlink = action.payload;
    },
    resetHyperlinkList: (state) => {
      state.hyperlinkList = [];
    },
  },
});
export const { setHyperlinkList, setSelectedHyperlink, resetHyperlinkList } =
  hyperlinkDocumentSlice.actions;

export const getHyperlinkList =
  (
    catId: string = "",
    areaId: string = "",
    instanceId: string = ""
  ): AppThunk =>
  (dispatch) => {
    getHyperlinks({ catId, areaId, instanceId }).then((res) => {
      dispatch(setHyperlinkList(res));
    });
  };

export const getHyperlink =
  (id: string): AppThunk =>
  (dispatch) => {
    getHyperlinksByCdn({ id }).then((res) => {
      dispatch(setSelectedHyperlink(res));
    });
  };

export const getHyperlinkByType =
  (id: string, type: string = ""): AppThunk =>
  (dispatch) => {
    getHyperlinkByCategoryType(id, type).then(
      (res) => {
        dispatch(setHyperlinkList(res));
      },
      (err) =>
        dispatch(
          addErrorMessage({
            title: i18n.t("ERRORSMSG.LOADHYPERLINKS"),
          })
        )
    );
  };

export const selectHyperlinkList = (state: RootState) =>
  state.hyperlinkDocument.hyperlinkList;
export const selectSelectedHyperlink = (state: RootState) =>
  state.hyperlinkDocument.selectedHyperlink;

export default hyperlinkDocumentSlice.reducer;
