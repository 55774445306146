import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  addErrorMessage,
  addSuccessMessage,
} from "../../../../../app/errors/errorSlice";
import { AppThunk, RootState } from "../../../../../app/store";
import i18n from "../../../../../config/i18n";
import { AclItem, HttpCodeItem } from "./model/config";
import {
  addAcl,
  getAclById,
  getAclByName,
  getAllAcl,
  getHttpCodes,
  removeAcl,
  updateAcl,
} from "./service/acl.service";

export interface IAclManagementSlice {
  listAcl: AclItem[];
  listHttpCode: HttpCodeItem[];
  selectedAcl?: AclItem;
  aclToEdit?: AclItem;
}

const initialState: IAclManagementSlice = {
  listAcl: [],
  listHttpCode: [],
  selectedAcl: undefined,
  aclToEdit: undefined,
};

export const aclManagementSlice = createSlice({
  name: "aclManagement",
  initialState,
  reducers: {
    setListAcl: (state, action: PayloadAction<AclItem[]>) => {
      state.listAcl = action.payload;
    },
    setAclToEdit: (state, action: PayloadAction<AclItem>) => {
      state.aclToEdit = action.payload;
    },
    setListHttpCode: (state, action: PayloadAction<HttpCodeItem[]>) => {
      state.listHttpCode = action.payload;
    },
    setSelectedAcl: (state, action: PayloadAction<AclItem>) => {
      state.selectedAcl = action.payload;
    },
    resetListAcl: (state) => {
      state.listAcl = [];
    },
    resetAclToEdit: (state) => {
      state.aclToEdit = undefined;
    },
    resetListHttpCode: (state) => {
      state.listHttpCode = [];
    },
    resetSelectedAcl: (state) => {
      state.selectedAcl = undefined;
    },
    addAclToAclList: (state, action: PayloadAction<AclItem>) => {
      state.listAcl = [...state.listAcl, action.payload];
    },
    removeAclFromAclList: (state, action: PayloadAction<string>) => {
      state.listAcl = [
        ...state.listAcl.filter((acl: AclItem) => acl._id !== action.payload),
      ];
    },
    editAclFromAclList: (state, action: PayloadAction<AclItem>) => {
      state.listAcl = [
        ...state.listAcl.filter(
          (acl: AclItem) => acl._id !== action.payload._id
        ),
        action.payload,
      ];
    },
  },
});

export const {
  setListAcl,
  setAclToEdit,
  setListHttpCode,
  setSelectedAcl,
  resetListAcl,
  resetAclToEdit,
  resetListHttpCode,
  resetSelectedAcl,
  addAclToAclList,
  removeAclFromAclList,
  editAclFromAclList,
} = aclManagementSlice.actions;

export const getAclList = (): AppThunk => (dispatch) => {
  getAllAcl().then(
    (res) => {
      dispatch(setListAcl(res));
    },
    (err) =>
      dispatch(
        addErrorMessage({
          title: i18n.t("ERRORSMSG.LOADINGACL"),
        })
      )
  );
};

export const searchAclByName =
  (name: string): AppThunk =>
  (dispatch) => {
    getAclByName(name).then(
      (res) => {
        dispatch(setListAcl(res));
      },
      (err) =>
        dispatch(
          addErrorMessage({
            title: i18n.t("ERRORSMSG.SEARCHACL"),
          })
        )
    );
  };

export const getHttpCodeList = (): AppThunk => (dispatch) => {
  getHttpCodes().then(
    (res) => {
      dispatch(setListHttpCode(res));
    },
    (err) =>
      dispatch(
        addErrorMessage({
          title: i18n.t("ERRORSMSG.LOADINGHTTPCODE"),
        })
      )
  );
};

export const searchAcl =
  (idAcl: string): AppThunk =>
  (dispatch) => {
    getAclById(idAcl).then(
      (res) => {
        dispatch(setListAcl([res]));
      },
      (err) =>
        dispatch(
          addErrorMessage({
            title: i18n.t("ERRORSMSG.SEARCHACL"),
          })
        )
    );
  };

export const createAclList =
  (data: AclItem, callBack: () => void): AppThunk =>
  (dispatch, getState) => {
    addAcl(data).then(
      (res) => {
        data._id = res._id;
        dispatch(addAclToAclList(data));
        callBack && callBack();
        dispatch(
          addSuccessMessage({
            title: i18n.t("SUCCESSMSG.INSERTACL"),
          })
        );
      },
      (err) =>
        dispatch(
          addErrorMessage({
            title: i18n.t("ERRORSMSG.INSERTACL"),
          })
        )
    );
  };

export const editAclAction =
  (data: AclItem, callBack: () => void): AppThunk =>
  (dispatch, getState) => {
    data._id &&
      updateAcl(data._id, data).then(
        (res) => {
          dispatch(editAclFromAclList(data));
          callBack && callBack();
          dispatch(resetAclToEdit());
          dispatch(
            addSuccessMessage({
              title: i18n.t("SUCCESSMSG.INSERTACL"),
            })
          );
        },
        (err) =>
          dispatch(
            addErrorMessage({
              title: i18n.t("ERRORSMSG.INSERTACL"),
            })
          )
      );
  };

export const deleteAcl =
  (idAcl: string): AppThunk =>
  (dispatch, getState) => {
    removeAcl(idAcl).then(
      (res) => {
        dispatch(removeAclFromAclList(idAcl));
        dispatch(
          addSuccessMessage({
            title: i18n.t("SUCCESSMSG.DELETEACL"),
          })
        );
      },
      (err) =>
        dispatch(
          addErrorMessage({
            title: i18n.t("ERRORSMSG.DELETEACL"),
          })
        )
    );
  };

export const selectAclList = (state: RootState) => state.aclManagement.listAcl;
export const selectAclToEdit = (state: RootState) =>
  state.aclManagement.aclToEdit;
export const selectHttpCodeList = (state: RootState) =>
  state.aclManagement.listHttpCode;
export const selectSelectedAcl = (state: RootState) =>
  state.aclManagement.selectedAcl;

export default aclManagementSlice.reducer;
