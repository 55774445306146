import React, { useContext, useEffect, useState } from "react";

import {
  generateCodeVerifier,
  generateRandomState,
  pkceChallengeFromVerifier,
} from "./utils";
import queryString from "query-string";
import axios from "axios";
import { interceptor } from "./interceptor";
import {
  clear,
  clearCodeVerifierAndSate,
  getAccessToken,
  getCodeVerifier,
  getRefreshToken,
  getState,
  setState as setStateLocalStorage,
  setCodeVerifier,
  setToken,
} from "./LocalStorageService";
import { TokenResponse } from "./models/TokenResponse";
import AuthEvent from "./authEvent";

export type AuthenticationOptions = {
  authorization_endpoint: string;
  token_endpoint: string;
  client_id: string;
  requested_scopes: string;
  redirect_uri: string;
  end_session_endpoint: string;
  realm: string;
};

export type State = "INIT" | "LOGIN" | "LOGGING" | "LOGGED" | "LOGOUT";

const AutenticationContext = React.createContext({
  login: (): void => {},
  logout: (): void => {},
  isAuthenticated: (): boolean => false,
  status: "INIT" as State,
});

export default function AuthenticationProvider(_props: {
  options: AuthenticationOptions;
  children: any;
}) {
  const client_id = process.env.REACT_APP_CLIENT_ID || "";
  const redirect_uri = `${window.location.protocol}//${
    window.location.hostname
  }${window.location.port !== "" ? `:${window.location.port}` : ""}`;

  const {
    authorization_endpoint,
    requested_scopes,
    token_endpoint,
    end_session_endpoint,
    realm,
  } = _props.options;

  const [status, setStatus] = useState(
    !!getState() ? "LOGGING" : ("LOGIN" as State)
  );
  useEffect(() => {
    const code = queryString.parse(window.location.search).code;
    const stateLocalStorage = getState();
    const code_verifier = getCodeVerifier();
    if (code && stateLocalStorage && code_verifier) {
      setStatus("LOGGING");
      const obj: any = {
        client_secret: "",
        grant_type: "authorization_code",
        client_id,
        redirect_uri,
        code,
        code_verifier,
      };
      // const queryString = Object.keys(obj).map(ele => ele + '=' + obj[ele]).join('&');
      axios
        .post(token_endpoint, queryString.stringify(obj), {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then(function ({ data }: { data: TokenResponse }) {
          setToken(data);
          window.location.href = redirect_uri;
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          clearCodeVerifierAndSate();
        });
    } else if (isAuthenticated()) {
      setStatus("LOGGED");
    } else {
      setStatus("LOGIN");
    }
  }, []);

  const refreshToken = async () => {
    try {
      const { data }: { data: TokenResponse } = await axios.post(
        token_endpoint,
        queryString.stringify({
          grant_type: "refresh_token",
          client_id,
          refresh_token: getRefreshToken(),
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      setToken(data);
      setStatus("LOGGED");
      return data;
    } catch (err) {
      console.log(err);
      logout();
      return {} as TokenResponse;
    }
  };
  interceptor(realm, refreshToken);

  const login = () => {
    const new_code_verifier = generateCodeVerifier();
    const new_state = generateRandomState();
    setStateLocalStorage(new_state);
    setCodeVerifier(new_code_verifier);
    window.location.href = initFlowUrl({
      authorization_endpoint,
      client_id,
      redirect_uri,
      requested_scopes,
      code_challenge: pkceChallengeFromVerifier(new_code_verifier),
      state: new_state,
      code_challenge_method: "S256",
    });
  };

  const logout = () => {
    clear();
    AuthEvent.logout.forEach((ele) => ele && ele());
    window.location.href = `${end_session_endpoint}?post_logout_redirect_uri=${redirect_uri}`;
  };

  const isAuthenticated = (): boolean => {
    return !!getAccessToken();
  };

  return (
    <AutenticationContext.Provider
      value={{
        login,
        logout,
        isAuthenticated,
        status,
      }}
    >
      {_props.children}
    </AutenticationContext.Provider>
  );
}

function initFlowUrl({
  authorization_endpoint,
  client_id,
  redirect_uri,
  requested_scopes,
  code_challenge,
  state,
  code_challenge_method = "S256",
}: {
  authorization_endpoint: string;
  client_id: string;
  redirect_uri: string;
  requested_scopes: string;
  code_challenge: string;
  state: string;
  code_challenge_method: "S256";
}) {
  return `${authorization_endpoint}?${queryString.stringify({
    response_type: "code",
    client_id,
    state,
    scope: requested_scopes,
    redirect_uri,
    code_challenge,
    code_challenge_method,
  })}`;
}

export function useAuthentication() {
  const { login, logout, isAuthenticated, status } = useContext(
    AutenticationContext
  );

  return {
    login,
    logout,
    isAuthenticated,
    status,
  };
}
