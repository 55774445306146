import { IIdResponse } from "./../../fabricJs/models/servicesResponse";
import { IPinMap, IPinMapBe } from "./../models/Pin";
import {
  IBookmarksBe,
  IBookmarksBePins,
  IMapBoundaryCoords,
} from "./../models/Map";
import http from "../../../../utils/http";
import omit from "lodash.omit";
import { copyObject } from "../../../../utils/objUtils";
import { subCategory } from "../../resources/ResourceData";

const urlMap = `/maps`;
const urlPinsInMap = `${urlMap}/pins`;
const bookmarks = `/bookmarks`;

export const getPinInArea = (
  boundaryCoords: IMapBoundaryCoords
): Promise<any[]> =>
  http.get(
    `${urlPinsInMap}/search/${boundaryCoords._northEast.lat}/${boundaryCoords._northEast.lng}/${boundaryCoords._southWest.lat}/${boundaryCoords._southWest.lng}`
  );

export const savePinInMap = (
  pin: Omit<IPinMapBe, "_id">
): Promise<IIdResponse> => {
  const copyPin = omit(copyObject(pin), [
    "_id",
    "coords",
    subCategory,
    "documentName",
  ]);
  return http.post(`${urlPinsInMap}`, { ...copyPin, sceneId: 0 });
};

export const editPinInMap = (pin: IPinMapBe): Promise<IPinMap> => {
  const copyPin = omit(copyObject(pin), [
    "_id",
    "coords",
    subCategory,
    "documentName",
  ]);
  return http.put(`${urlPinsInMap}/${pin._id}`, { ...copyPin, sceneId: 0 });
};

export const patchPinInMap = (
  pinId: string,
  value: { [key: string]: any }
): Promise<any> => http.patch(`${urlPinsInMap}/${pinId}`, value);

export const deletePinInMap = (pin: IPinMap): Promise<any[]> =>
  http.delete(`${urlPinsInMap}/${pin._id}`);

export const getBookmarkList = (): Promise<IBookmarksBe> => {
  return http.get(`${bookmarks}`);
};

export const createBookmarkList = (
  pinsIdList: string[]
): Promise<IBookmarksBePins> => {
  return http.post(`${bookmarks}`, { pins: pinsIdList });
};

export const modifyBookmarkList = (
  pinsIdList: string[]
): Promise<IBookmarksBePins> => {
  return http.put(`${bookmarks}`, { pins: pinsIdList });
};
export const deleteBookmarkList = (): Promise<any> => {
  return http.delete(`${bookmarks}`);
};
