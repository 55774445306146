import React from "react";
import AuthLayout from "./AuthLayout";

const AuthRoute = () => {
  return (
    <>
      <AuthLayout />
    </>
  );
};

export default AuthRoute;
