import { ICategory } from "../features/auth/models/Categories";
import { MicrogameItem } from "./../features/auth/game/microGame/model/gameList";

export const createCategoriesOption = (categories: Partial<ICategory>[]) => {
  return categories.map((cat) => ({
    value: `${cat.id}`,
    label: cat.name || "",
  }));
};
export const createResourcesOption = (hyperlink: any[]) => {
  return hyperlink.map((link) => ({
    value: link._id,
    label: link.description,
  }));
};
export const createMicrogameOption = (resourceId: MicrogameItem[]) => {
  return resourceId.map((micro) => ({
    value: "" + micro.categoryId,
    label: micro.name,
  }));
};
