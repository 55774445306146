import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { IBackgroundScene } from "../features/auth/fabricJs/models/BackgroundScene";
import { IBackgroundCommon } from "./auth/model/BgAvailable";

export interface BgAvailableSliceModel {
  bgListAvailable: IBackgroundCommon[];
}
const initialState: BgAvailableSliceModel = {
  bgListAvailable: [],
};

export const bgAvailableSlice = createSlice({
  name: "bgAvailable",
  initialState,
  reducers: {
    setBgListAvailable: (state, action: PayloadAction<IBackgroundCommon>) => {
      state.bgListAvailable.push(action.payload);
    },
  },
});
export const { setBgListAvailable } = bgAvailableSlice.actions;

export const selectBgListAvailable = (state: RootState) =>
  state.bgAvailable.bgListAvailable;

export default bgAvailableSlice.reducer;
