import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  addErrorMessage,
  addSuccessMessage,
} from "../../../../../app/errors/errorSlice";
import { AppThunk, RootState } from "../../../../../app/store";
import {
  resetModal,
  setModal,
} from "../../../../../components/shared/redux/modal/modalSlice";
import i18n from "../../../../../config/i18n";
import { isArray } from "../../../../../utils/arrayUtils";
import {
  addMenuInMenuList,
  editMenuInMenuList,
  removeMenuFromMenuList,
} from "../../menuSlice";
import { IMenuManagerItem } from "./model/config";
import {
  createMenuItem,
  deleteMenuItem,
  getMenuItemById,
  getMenuItems,
  updateMenuItem,
} from "./services/menuManager.service";

interface MenuManagerSlice {
  menuList: IMenuManagerItem[];
  selectedMenu?: IMenuManagerItem;
  subMenuList: IMenuManagerItem[];
  menuToEdit?: IMenuManagerItem;
}

const initialState: MenuManagerSlice = {
  menuList: [],
  selectedMenu: undefined,
  subMenuList: [],
  menuToEdit: undefined,
};

export const menuManagerSlice = createSlice({
  name: "menuManager",
  initialState,
  reducers: {
    setMenuList: (state, action: PayloadAction<IMenuManagerItem[]>) => {
      state.menuList = action.payload;
    },
    setSelectedMenu: (state, action: PayloadAction<IMenuManagerItem>) => {
      state.selectedMenu = action.payload;
    },
    setSubMenuList: (state, action: PayloadAction<IMenuManagerItem[]>) => {
      state.subMenuList = action.payload;
    },
    setMenuToEdit: (state, action: PayloadAction<IMenuManagerItem>) => {
      state.menuToEdit = action.payload;
    },
    resetMenuList: (state) => {
      state.menuList = [];
    },
    resetSelectedMenu: (state) => {
      state.selectedMenu = undefined;
    },
    resetSubMenuList: (state) => {
      state.subMenuList = [];
    },
    resetMenuToEdit: (state) => {
      state.menuToEdit = undefined;
    },
    removeMenuFromList: (state, action: PayloadAction<string>) => {
      state.menuList = state.menuList.filter(
        (menu) => menu._id !== action.payload
      );
    },
    removeSubMenuFromList: (state, action: PayloadAction<string>) => {
      state.subMenuList = state.subMenuList.filter(
        (subMenu) => subMenu._id !== action.payload
      );
    },
    addMenuInList: (state, action: PayloadAction<IMenuManagerItem>) => {
      state.menuList = [
        ...state.menuList.filter((menu) => menu._id !== action.payload._id),
        action.payload,
      ];
    },
    addSubmenuInList: (state, action: PayloadAction<IMenuManagerItem>) => {
      state.subMenuList = [
        ...state.subMenuList.filter(
          (subMenu) => subMenu._id !== action.payload._id
        ),
        action.payload,
      ];
    },
  },
});

export const {
  setMenuList,
  setSelectedMenu,
  setSubMenuList,
  setMenuToEdit,
  resetMenuList,
  resetSelectedMenu,
  resetSubMenuList,
  resetMenuToEdit,
  removeMenuFromList,
  removeSubMenuFromList,
  addMenuInList,
  addSubmenuInList,
} = menuManagerSlice.actions;

export const resetAllMenus = (): AppThunk => (dispatch) => {
  dispatch(resetMenuList());
  dispatch(resetSelectedMenu());
  dispatch(resetSubMenuList());
  dispatch(resetMenuToEdit());
};

export const getMenuById =
  (idMenu: string): AppThunk =>
  (dispatch) => {
    getMenuItemById(idMenu).then(
      (res: any) => {
        dispatch(setMenuList(isArray(res) ? res : [res]));
      },
      (err: any) =>
        dispatch(
          addErrorMessage({
            title: i18n.t("ERRORSMSG.MENU"),
          })
        )
    );
  };

export const getAllMenuList = (): AppThunk => (dispatch) => {
  getMenuItems().then(
    (res: any) => {
      dispatch(setMenuList(isArray(res) ? res : [res]));
    },
    (err: any) =>
      dispatch(
        addErrorMessage({
          title: i18n.t("ERRORSMSG.MENU"),
        })
      )
  );
};

export const deleteMenuAction =
  (idMenu: string): AppThunk =>
  (dispatch, getState) => {
    const buttonList = [
      {
        label: i18n.t("MODAL.CANCELL"),
        action: "cancelDelete",
        type: "secondary",
        dataType: {},
      },
      {
        label: i18n.t("MODAL.CONFIRM"),
        action: "confirmDelete",
        type: "primary",
        dataType: {},
      },
    ];
    const confirmDelete = () => {
      deleteMenuItem(idMenu).then(
        (res: any) => {
          dispatch(removeMenuFromList(idMenu));
          dispatch(removeSubMenuFromList(idMenu));
          dispatch(removeMenuFromMenuList(idMenu));
          dispatch(resetModal());
          dispatch(
            addSuccessMessage({
              title: i18n.t("SUCCESSMSG.DELETEMENU"),
            })
          );
        },
        (err: any) => {
          dispatch(resetModal());
          dispatch(
            addErrorMessage({
              title: i18n.t("ERRORSMSG.DELETEMENU"),
            })
          );
        }
      );
    };
    const cancelDelete = () => {
      dispatch(resetModal());
    };
    dispatch(
      setModal(
        buttonList,
        {
          title: i18n.t("MODAL.TITLE"),
          body: `Sei sicuro di voler cancellare il menu _id: ${idMenu}`,
        },
        {
          cancelDelete,
          confirmDelete,
        }
      )
    );
  };

export const addMenuAction =
  (data: any, callBack: () => void): AppThunk =>
  (dispatch, getState) => {
    createMenuItem(data).then(
      (res: any) => {
        data._id = res._id;
        dispatch(addMenuInList(data));
        dispatch(addSubmenuInList(data));
        dispatch(addMenuInMenuList(data));
        callBack && callBack();
        dispatch(
          addSuccessMessage({
            title: i18n.t("SUCCESSMSG.ADDMENU"),
          })
        );
      },
      (err: any) =>
        dispatch(
          addErrorMessage({
            title: i18n.t("ERRORSMSG.ADDMENU"),
          })
        )
    );
  };

export const editMenuAction =
  (data: any, callBack: () => void): AppThunk =>
  (dispatch, getState) => {
    updateMenuItem(data._id, data).then(
      (res: any) => {
        dispatch(addMenuInList(data));
        dispatch(setSelectedMenu(data));
        dispatch(editMenuInMenuList(data));
        callBack && callBack();
        dispatch(resetMenuToEdit());
        dispatch(
          addSuccessMessage({
            title: i18n.t("SUCCESSMSG.EDITMENU"),
          })
        );
      },
      (err: any) =>
        dispatch(
          addErrorMessage({
            title: i18n.t("ERRORSMSG.EDITMENU"),
          })
        )
    );
  };

export const getListSubMenu =
  (idMenu?: string): AppThunk =>
  (dispatch) => {
    idMenu &&
      getMenuItemById(idMenu).then(
        (res: any) => {
          res.childrenItems && dispatch(setSubMenuList(res.childrenItems));
        },
        (err: any) =>
          dispatch(
            addErrorMessage({
              title: i18n.t("ERRORSMSG.LOADSUBMENU"),
            })
          )
      );
  };

export const selectMenuList = (state: RootState) => state.menuManager.menuList;
export const selectSelectedMenu = (state: RootState) =>
  state.menuManager.selectedMenu;
export const selectSubMenuList = (state: RootState) =>
  state.menuManager.subMenuList;
export const selectMenuToEdit = (state: RootState) =>
  state.menuManager.menuToEdit;

export default menuManagerSlice.reducer;
