import { addSuccessMessage } from "./../../../../../app/errors/errorSlice";
import { IRole, IUserRole } from "./models/userRole";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { addErrorMessage } from "../../../../../app/errors/errorSlice";
import { AppThunk, RootState } from "../../../../../app/store";
import i18n from "../../../../../config/i18n";
import {
  addUser,
  editUser,
  getRolesAvailable,
  getUsers,
  getUsersRoleBySubject,
} from "./services/roles.service";

export interface IRolesManagementSlice {
  listUser: IUserRole[];
  listRolesAvailable: IRole[];
  selectedUser: IUserRole | undefined;
  userToEdit: IUserRole | undefined;
}
const initialState: IRolesManagementSlice = {
  listUser: [],
  listRolesAvailable: [],
  selectedUser: undefined,
  userToEdit: undefined,
};

export const roleManagementSlice = createSlice({
  name: "roleManagement",
  initialState,
  reducers: {
    setListUser: (state, action: PayloadAction<IUserRole[]>) => {
      state.listUser = action.payload;
    },
    setListRolesAvailable: (state, action: PayloadAction<IRole[]>) => {
      state.listRolesAvailable = action.payload;
    },
    setSelectedUser: (state, action: PayloadAction<IUserRole>) => {
      state.selectedUser = action.payload;
    },
    setUserToEdit: (state, action: PayloadAction<IUserRole>) => {
      state.userToEdit = action.payload;
    },
    resetListUser: (state) => {
      state.listUser = [];
    },
    resetSelectedUser: (state) => {
      state.selectedUser = undefined;
    },
    resetUserToEdit: (state) => {
      state.userToEdit = undefined;
    },
  },
});

export const {
  setListUser,
  setListRolesAvailable,
  setSelectedUser,
  setUserToEdit,
  resetListUser,
  resetSelectedUser,
  resetUserToEdit,
} = roleManagementSlice.actions;

export const getListUser =
  ({ searchQuery }: { searchQuery?: string }): AppThunk =>
  (dispatch) => {
    getUsers({ searchQuery }).then(
      (res: any) => {
        dispatch(setListUser(res));
      },
      (err: any) =>
        dispatch(
          addErrorMessage({
            title: i18n.t("ERRORSMSG.LOADINGUSER"),
          })
        )
    );
  };
export const getUserBySubject =
  (subject: string): AppThunk =>
  (dispatch, getState) => {
    getUsersRoleBySubject(subject).then(
      (res: IUserRole) => {
        dispatch(setUserToEdit(res));
      },
      (err: any) =>
        dispatch(
          addErrorMessage({
            title: i18n.t("ERRORSMSG.LOADINGUSER"),
          })
        )
    );
  };
export const getListRolesAvailable = (): AppThunk => (dispatch) => {
  getRolesAvailable().then(
    (res: any) => {
      dispatch(setListRolesAvailable(res));
    },
    (err: any) =>
      dispatch(
        addErrorMessage({
          title: i18n.t("ERRORSMSG.LOADROLES"),
        })
      )
  );
};

export const addUserAction =
  (data: any, callBack: () => void): AppThunk =>
  (dispatch, getState) => {
    addUser(data).then(
      (res: any) => {
        const newListUser = [...getState().roleManagement.listUser, data];

        dispatch(setListUser(newListUser));
        callBack && callBack();
        dispatch(
          addSuccessMessage({
            title: i18n.t("SUCCESSMSG.ADDUSER"),
          })
        );
      },
      (err: any) =>
        dispatch(
          addErrorMessage({
            title: i18n.t("ERRORSMSG.ADDUSER"),
          })
        )
    );
  };

export const editUserAction =
  (data: IUserRole, callBack?: () => void): AppThunk =>
  (dispatch, getState) => {
    editUser(data).then(
      (res: any) => {
        callBack && callBack();
        dispatch(
          addSuccessMessage({
            title: i18n.t("SUCCESSMSG.EDITUSER"),
          })
        );
      },
      (err: any) =>
        dispatch(
          addErrorMessage({
            title: i18n.t("ERRORSMSG.EDITUSER"),
          })
        )
    );
  };

export const resetAllUser = (): AppThunk => (dispatch) => {
  dispatch(resetListUser());
  dispatch(resetSelectedUser());
  dispatch(resetUserToEdit());
};

export const selectListUser = (state: RootState) =>
  state.roleManagement.listUser;

export const selectListRoles = (state: RootState) =>
  state.roleManagement.listRolesAvailable;

export const selectSelectedUser = (state: RootState) =>
  state.roleManagement.selectedUser;

export const selectUserToEdit = (state: RootState) =>
  state.roleManagement.userToEdit;

export default roleManagementSlice.reducer;
