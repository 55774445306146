import { SingleRoute } from "../utils/routes/models/routes";
import * as routeName from "./routeNameAuthType";

const routeMicroGame: SingleRoute[] = [
  {
    path: "/categories/:id/areas/:areaId",
    component: "features/auth/game/microGame/MicroGameLayout",
    name: routeName.microgameList,
  },
];
export default routeMicroGame;
