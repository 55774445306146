import React from "react";
import { useTranslation } from "react-i18next";
import { OptionSelect } from "../../../utils/forms/models/FormElementModel";

const AutoComplete = ({
  error = false,
  name,
  value,
  options = [],
  onChangeAction = () => {},
  disabled = false,
}: {
  error: boolean;
  name: string;
  value: string;
  options: OptionSelect[];
  onChangeAction?: (
    data: any,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  disabled: boolean;
}) => {
  const { t } = useTranslation();

  const changeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeAction && onChangeAction(event.target.value, event);
  };

  return (
    <>
      <input
        className="shared-input form-control"
        list={name + "-list"}
        name={name}
        onChange={changeInput}
        autoComplete="off"
        value={value ? value : ""}
        placeholder={t("ACL.FINDNAME")}
        disabled={disabled}
      />
      <datalist id={name + "-list"}>
        {options.length > 0 &&
          options.map((option: OptionSelect) => {
            return <option value={option.label} />;
          })}
      </datalist>
    </>
  );
};

export default AutoComplete;
