import React from "react";
import { useSelector } from "react-redux";
import {
  dispatchEventModal,
  selectBtnList,
  selectShow,
  selectSize,
  selectTemplateBody,
  selectText,
} from "./modalSlice";
import modalEvents from "./modalEvent";
import ModalWarning from "../../design/errors/modals/modalWarning/ModalWarning";

const ModalApp = () => {
  const show = useSelector(selectShow);
  const buttonList = useSelector(selectBtnList);
  const text = useSelector(selectText);
  const size = useSelector(selectSize);
  const selectedTemplate = useSelector(selectTemplateBody);

  const template = selectedTemplate
    ? modalEvents.templates[selectedTemplate]
    : undefined;

  return (
    <ModalWarning
      showModal={show}
      text={text}
      size={size}
      btnList={buttonList}
      templateBody={template}
      clickAction={(action) => dispatchEventModal(action)}
    />
  );
};
export default ModalApp;
