import React from "react";
import { useAuthentication } from "../Authentication";

function Public(_props: { children: any }) {
  const { isAuthenticated, status } = useAuthentication();

  return (
    <div>{!isAuthenticated() && status === "LOGIN" && _props.children}</div>
  );
}

export default Public;
