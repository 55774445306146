import { IUser } from "./model/User";

export const user: IUser = {
  firstName: "Pippo",
  lastName: "Franco",
  enabled: true,
  email: "pippo.franco@mail.it",
  roles: ["admin"],
  role: 1,
  permissions: [""],
  username: "pFranco88",
  id: 1,
  companyId: 0,
  company: 0,
};
