import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getUserLoggedIn, selectUser } from "../../../../app/auth/authSlice";
import i18n from "../../../../config/i18n";
import { useAuthentication } from "../../auth/Authentication";
import FooterLayout from "./FooterLayout";
import HeaderLayout from "./HeaderLayout";

interface AuthLayoutProps {
  children: React.ReactNode;
}
const AuthLayout = ({ children }: AuthLayoutProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const { logout } = useAuthentication();

  useEffect(() => {
    dispatch(getUserLoggedIn());
  }, []);
  useEffect(() => {
    i18n.changeLanguage(user?.preference.language);
  }, [user]);

  return (
    <>
      <div id="authLayout">
        <div className="containerApp">
          <HeaderLayout logout={logout}></HeaderLayout>
          <div className="main-auth">
            <Container fluid>
              <main>{children}</main>
            </Container>
          </div>
        </div>
      </div>
      <FooterLayout></FooterLayout>
    </>
  );
};

export default AuthLayout;
