import React from "react";
import publicRoute from "../../routes/public";
import DynamicRoute from "./DynamicRoute";

interface PublicRouteProps {
  isLogged: boolean;
}
const PublicRoute = ({ isLogged }: PublicRouteProps) => {
  return (
    <>
      {!isLogged ? (
        <div className="public">
          <div>
            <div className="d-flex justify-content-between mb-3"></div>
            <DynamicRoute routes={publicRoute} />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default PublicRoute;
