import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import omit from "lodash.omit";
import {
  addErrorMessage,
  addSuccessMessage,
} from "../../../../app/errors/errorSlice";
import {
  resetCounterHistoryItem,
  resetHistorySelectedItem,
  revertHistorySelectedItem,
  setCounterHistoryItem,
  setHistorySelectedItem,
} from "../../../../app/historyItemSlice";
import { AppThunk, RootState } from "../../../../app/store";
import i18n from "../../../../config/i18n";
import { copyObject } from "../../../../utils/objUtils";
import { ICounter } from "../../data/itemHistory";
import { deleteFile, getFiles } from "../../services/upload.service";
import {
  initListBgGameAvailable,
  layoutGameInit,
  listTabNameGame,
} from "./gamePlatformData";
import { IBackgroundGame } from "./models/backgroundGame";
import { ILayoutGame } from "./models/layoutGame";
import {
  deleteBgForGame,
  deleteLayoutForGame,
  editBgForGame,
  editLayoutForGame,
  getBgForGame,
  getLayoutForGame,
  saveBgForGame,
  saveLayoutForGame,
} from "./services/gamePlatformLayout.service";

export interface MapSliceModel {
  backgroundGame?: IBackgroundGame;
  selectedBackgroundGame?: IBackgroundGame;
  listBackgroundGameAvailable: IBackgroundGame[];
  isGameStarted: boolean;
  tabUploadKeyGame: string;
  layoutGame: ILayoutGame;
  editedLayoutGame?: ILayoutGame;
}
const initialState: MapSliceModel = {
  backgroundGame: undefined,
  selectedBackgroundGame: undefined,
  listBackgroundGameAvailable: initListBgGameAvailable,
  isGameStarted: false,
  tabUploadKeyGame: listTabNameGame.background,
  layoutGame: layoutGameInit,
  editedLayoutGame: undefined,
};

export const gamePlatformSlice = createSlice({
  name: "gamePlatform",
  initialState,
  reducers: {
    setBackgroundGame: (state, action: PayloadAction<IBackgroundGame>) => {
      state.backgroundGame = action.payload;
    },
    setSelectedBackgroundGame: (
      state,
      action: PayloadAction<IBackgroundGame>
    ) => {
      state.selectedBackgroundGame = action.payload;
    },
    resetSelectedBgGame: (state) => {
      state.selectedBackgroundGame = undefined;
    },
    removeBgGame: (state) => {
      state.backgroundGame = undefined;
    },
    setAvailableBackgroundGameList: (
      state,
      action: PayloadAction<IBackgroundGame[]>
    ) => {
      state.listBackgroundGameAvailable = action.payload;
    },
    addAvailableBackgroundGameList: (
      state,
      action: PayloadAction<IBackgroundGame>
    ) => {
      state.listBackgroundGameAvailable = [
        ...state.listBackgroundGameAvailable.filter(
          (ele) => ele.url !== action.payload.url
        ),
        action.payload,
      ];
    },
    removeBgGameAvailable: (state, action: PayloadAction<IBackgroundGame>) => {
      state.listBackgroundGameAvailable =
        state.listBackgroundGameAvailable.filter(
          (img) => img.url !== action.payload.url
        );
    },
    setIsGameStarted: (state, action: PayloadAction<boolean>) => {
      state.isGameStarted = action.payload;
    },
    setTabsKeyGame: (state, action: PayloadAction<string>) => {
      state.tabUploadKeyGame = action.payload;
    },
    setLayoutGame: (state, action: PayloadAction<ILayoutGame>) => {
      state.layoutGame = action.payload;
    },
    setEditedLayoutGame: (state, action: PayloadAction<ILayoutGame>) => {
      state.editedLayoutGame = action.payload;
    },
    resetEditedLayoutGame: (state) => {
      state.editedLayoutGame = undefined;
    },
  },
});
export const {
  setBackgroundGame,
  setSelectedBackgroundGame,
  resetSelectedBgGame,
  removeBgGame,
  removeBgGameAvailable,
  setAvailableBackgroundGameList,
  addAvailableBackgroundGameList,
  setIsGameStarted,
  setTabsKeyGame,
  setLayoutGame,
  setEditedLayoutGame,
  resetEditedLayoutGame,
} = gamePlatformSlice.actions;

export const getBackgroundForGame =
  (idGame: string): AppThunk =>
  (dispatch) => {
    getBgForGame(idGame).then((res) => {
      dispatch(setBackgroundGame(res[0]));
    });
  };

export const addBgInGame =
  (background: IBackgroundGame): AppThunk =>
  (dispatch) => {
    const backgroundConId = {
      ...background,
      id_temp: (Math.random() * 10).toString(),
    };
    dispatch(setSelectedBackgroundGame(backgroundConId));
  };

export const getListBgAvailableGame = (): AppThunk => (dispatch) => {
  getFiles({ pathName: "backgrounds" }).then(
    (data) => {
      const newList = data.map((bg) => {
        return {
          url: bg.url,
          name: bg.originalfilename,
          initialHeight: 1920,
          initialWidth: 1080,
          type: bg.mimetype,
        };
      });
      dispatch(setAvailableBackgroundGameList(newList));
    },
    (err) =>
      dispatch(
        addErrorMessage({
          title: i18n.t("ERRORSMSG.LOADINGBGSSAVAILABLE"),
        })
      )
  );
};

export const saveBgGame =  //nuovo bg da upload il name puo essere "" da dire al BE
  (bg: IBackgroundGame, idGame: string): AppThunk =>
  (dispatch, getState) => {
    const backgroundGame = getState().gamePlatform.backgroundGame;
    const copyBg = copyObject(bg);
    if (bg.id_temp && !backgroundGame) {
      delete copyBg.id_temp;
      saveBgForGame(bg, idGame).then((res) => {
        copyBg._id = res._id;
        dispatch(resetSelectedBgGame());
        return dispatch(setBackgroundGame(copyBg));
      });
    }
    if (bg.id_temp && backgroundGame) {
      const editBg = { ...bg, _id: backgroundGame._id };
      editBgForGame(editBg, idGame).then((res) => {
        dispatch(addAvailableBackgroundGameList(editBg));
        return dispatch(setBackgroundGame(editBg));
      });
    }
  };

export const selectActionKeyTab =
  (keyTab: string): AppThunk =>
  (dispatch, getState) => {
    const savedLayout = getState().gamePlatform.layoutGame;
    const editedLayout = getState().gamePlatform.editedLayoutGame;
    if (
      (!editedLayout || (editedLayout && !editedLayout.id_temp)) &&
      savedLayout.gameId === "" &&
      keyTab === listTabNameGame.layout
    ) {
      const layoutConId = {
        ...layoutGameInit,
        id_temp: (Math.random() * 10).toString(),
      };
      dispatch(updateLayoutGame(layoutConId));
    } else if (
      savedLayout.gameId !== "" &&
      savedLayout.gameId !== editedLayout?.gameId
    ) {
      dispatch(updateLayoutGame(savedLayout));
    }
    dispatch(setTabsKeyGame(keyTab));
  };

export const getHistoryLayoutGame =
  (azione: ICounter): AppThunk =>
  (dispatch, getState) => {
    const historyPin = getState().historyItem
      .historySelectedItem as ILayoutGame[];
    const counter = getState().historyItem.counterHistoryItem;
    dispatch(setCounterHistoryItem(azione));
    dispatch(
      setEditedLayoutGame(
        historyPin[azione === "next" ? counter + 1 : counter - 1]
      )
    );
  };

export const changeHistoryAndCounterGame =
  (layout?: ILayoutGame, azione?: ICounter): AppThunk =>
  (dispatch) => {
    layout
      ? dispatch(setHistorySelectedItem(layout))
      : dispatch(resetHistorySelectedItem());
    azione
      ? dispatch(setCounterHistoryItem(azione))
      : dispatch(resetCounterHistoryItem());
  };

export const updateLayoutGame =
  (layout: ILayoutGame): AppThunk =>
  (dispatch) => {
    dispatch(setEditedLayoutGame(layout));
    dispatch(changeHistoryAndCounterGame(layout, ICounter.NEXT));
  };

export const getLayoutGame =
  (idGame: string): AppThunk =>
  (dispatch) => {
    getLayoutForGame(idGame).then((res) => {
      res.length && dispatch(setLayoutGame(res[0]));
    });
  };
export const saveLayoutGame =
  (layout: ILayoutGame, idGame: string): AppThunk =>
  (dispatch) => {
    const copylayout = copyObject(layout);
    delete copylayout.newIconVite;
    if (copylayout.id_temp) {
      saveLayoutForGame(copylayout, idGame).then((res) => {
        const newlayout = omit(copylayout, ["id_temp"]);
        dispatch(resetEditedLayoutGame());
        dispatch(changeHistoryAndCounterGame());
        newlayout._id = res._id;
        return dispatch(setLayoutGame(newlayout));
      });
    } else {
      editLayoutForGame(copylayout, idGame).then((res) => {
        copylayout._id = res._id;
        dispatch(setLayoutGame(copylayout));
      });
    }
  };

export const removeEditedLayout =
  (idGame: string): AppThunk =>
  (dispatch, getState) => {
    const layout = getState().gamePlatform.layoutGame;
    deleteLayoutForGame(layout, idGame).then(() => {
      dispatch(resetEditedLayoutGame());
      dispatch(changeHistoryAndCounterGame());
      dispatch(setLayoutGame(layoutGameInit));
    });
  };

export const deleteBgGame =
  (id: string): AppThunk =>
  (dispatch, getState) => {
    const bg = getState().gamePlatform.backgroundGame;
    bg &&
      deleteBgForGame(bg, id).then(() => {
        dispatch(removeBgGame());
        dispatch(resetSelectedBgGame());
      });
  };

export const deleteBgAvailable =
  (bg: IBackgroundGame): AppThunk =>
  (dispatch) => {
    const documentName = "backgrounds/" + bg.url.split("/").pop();
    documentName &&
      deleteFile(documentName).then(
        (data) => {
          dispatch(removeBgGameAvailable(bg));
          dispatch(
            addSuccessMessage({
              title: i18n.t("SUCCESSMSG.DELETEBGAVAILABLE"),
            })
          );
        },
        (err) =>
          dispatch(
            addErrorMessage({
              title: i18n.t("ERRORSMSG.DELETEBGAVAILABLE"),
            })
          )
      );
  };

export const revertHistoryFirstEditedLayoutGame =
  (): AppThunk => (dispatch, getState) => {
    dispatch(revertHistorySelectedItem(1));
    dispatch(resetCounterHistoryItem(1));
    dispatch(
      setEditedLayoutGame(
        getState().historyItem.historySelectedItem[0] as ILayoutGame
      )
    );
  };

export const selectBackgroundGame = (state: RootState) =>
  state.gamePlatform.backgroundGame;
export const selectSelectedBackgroundGame = (state: RootState) =>
  state.gamePlatform.selectedBackgroundGame;
export const selectListBackgroundGameAvailable = (state: RootState) =>
  state.gamePlatform.listBackgroundGameAvailable;
export const selectIsGameStarted = (state: RootState) =>
  state.gamePlatform.isGameStarted;
export const selectTabUploadKeyGame = (state: RootState) =>
  state.gamePlatform.tabUploadKeyGame;
export const selectLayoutGame = (state: RootState) =>
  state.gamePlatform.layoutGame;
export const selectEditedLayoutGame = (state: RootState) =>
  state.gamePlatform.editedLayoutGame;

export const selectBackgroundGameNew = createSelector(
  selectSelectedBackgroundGame,
  selectBackgroundGame,
  (selectedBg, savedBg) => {
    let newBgGame: IBackgroundGame;
    if (selectedBg && selectedBg.id_temp) {
      newBgGame = copyObject(selectedBg);
      return newBgGame;
    }
    return savedBg;
  }
);

export const newLayoutGame = createSelector(
  selectEditedLayoutGame,
  selectLayoutGame,
  (editedLayout, savedLayout) => {
    let newLayout: ILayoutGame;
    if (
      editedLayout &&
      (editedLayout.id_temp || editedLayout.gameId === savedLayout.gameId)
    ) {
      newLayout = copyObject(editedLayout);
      return newLayout;
    }
    return savedLayout;
  }
);

export default gamePlatformSlice.reducer;
