import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import useWindowSize from "../../../features/auth/fabricJs/useWindowSize";

type Size = {
  width?: number | string;
  height?: number | string;
  frameBorder?: number | string;
};

function IEFrame(props: {
  src: string;
  title: string;
  width?: number | undefined;
  height?: number | undefined;
  frameBorder?: number | undefined;
}) {
  const [size, setSize] = useState<Size>({
    width: props.width || "100%",
    height: props.height,
  });

  const conteinerRef = useRef<HTMLDivElement | null>(null);
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  const { size: windowSize } = useWindowSize();

  useEffect(() => {
    setSize({
      width: props.width || "100%",
      height: props.height,
    });
  }, [props.height, props.width]);

  useLayoutEffect(() => {
    resize(conteinerRef.current);
  }, [conteinerRef, windowSize]);

  function resize(r: HTMLDivElement | null) {
    if (props.height && props.width) {
      return;
    }
    const width = r?.offsetWidth || props.width || 0;
    const toHeight = (width * 1080) / 1920;
    const parentH = props.height || windowSize[1] - 20 - 80;
    if (toHeight > parentH) {
      const toWidth = (1920 * parentH) / 1080;
      if (size.width !== toWidth || size.height !== parentH) {
        setSize({ width: toWidth, height: parentH });
      }
    } else if (size.height !== toHeight) {
      setSize({ width: "100%", height: toHeight });
    }
  }

  return (
    <div ref={resize} className={`d-flex justify-content-center`}>
      <div
        ref={conteinerRef}
        style={{
          width: size.width,
          height: size.height,
        }}
      >
        <iframe
          ref={iframeRef}
          src={props.src}
          title={props.title}
          width="100%"
          height="100%"
          frameBorder={props.frameBorder || 0}
        />
      </div>
    </div>
  );
}

export default IEFrame;
