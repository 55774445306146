import React, { lazy } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import LoaderFullScreenApp from "../../app/load/LoaderFullScreenApp";
import { Routes } from "../../utils/routes/models/routes";

interface DynamicRouteProps {
  routes: Routes;
  layouts?: {
    name: string;
    component: (data: React.ReactNode) => React.ReactNode;
  }[];
}
const DynamicRoute = ({ routes, layouts }: DynamicRouteProps) => {
  return (
    <React.Suspense fallback={<LoaderFullScreenApp></LoaderFullScreenApp>}>
      <Switch>
        {layouts &&
          layouts.length &&
          layouts.map((layout) => (
            <Route
              key={layout.name}
              path={routes.list
                .filter((ele) => ele.layout === layout.name)
                .map((el) => el.path)}
            >
              {layout.component(
                routes.list
                  .filter((ele) => ele.layout === layout.name)
                  .map((ele) => {
                    const route =
                      typeof ele.component === "string"
                        ? lazy(() => import("../../" + ele.component))
                        : ele.component;
                    return (
                      <Route
                        key={ele.path}
                        exact
                        path={ele.path}
                        component={route}
                      />
                    );
                  })
              )}
            </Route>
          ))}
        {routes.list
          .filter((ele) => !ele.layout)
          .map((ele) => {
            const route =
              typeof ele.component === "string"
                ? lazy(() => import("../../" + ele.component))
                : ele.component;
            return (
              <Route key={ele.path} exact path={ele.path} component={route} />
            );
          })}
        {routes.redirect ? <Redirect from="*" to={routes.redirect} /> : null}
      </Switch>
    </React.Suspense>
  );
};

export default DynamicRoute;
