import omit from "lodash.omit";
import { IBadgeConfig } from "./../models/badge";
import http from "../../../../../utils/http";

const urlbadgeConfig = `/badge-configuration`;

export const getAllBadge = (): Promise<IBadgeConfig[]> =>
  http.get(`${urlbadgeConfig}/badge`);
export const getAllBadgefromCatId = (catId: string): Promise<IBadgeConfig[]> =>
  http.get(`${urlbadgeConfig}/badge/${catId}`);

export const saveBadgeforCatId = (
  data: IBadgeConfig
): Promise<IBadgeConfig[]> => {
  const newData = omit(data, ["categoryId", "gameId", "_class"]);
  return http.post(`${urlbadgeConfig}/badge/${data.categoryId}`, newData);
};
export const editBadgeforCatId = (
  data: IBadgeConfig
): Promise<IBadgeConfig[]> => {
  const newData = omit(data, ["categoryId", "gameId", "_id", "id", "_class"]);
  return http.put(
    `${urlbadgeConfig}/badge/${data.categoryId}/${data._id}`,
    newData
  );
};
export const deleteBadge = (data: IBadgeConfig): Promise<IBadgeConfig[]> => {
  return http.delete(`${urlbadgeConfig}/badge/${data.categoryId}/${data._id}`);
};
