import React from "react";
import { CardProps } from "react-bootstrap/Card";
import { Card as Crd } from "react-bootstrap";
import { mergeClass } from "../functions/function";

interface CardBrandProps
  extends CardProps,
    React.HTMLAttributes<HTMLButtonElement> {
  bgColor?: string;
}
const ContentBox = ({
  bgColor = "default",
  children,
  className,
  ...rest
}: CardBrandProps) => {
  return (
    <Crd
      className={mergeClass("shared-boxContent " + bgColor + " " + className)}
      {...rest}
    >
      <Crd.Body>{children}</Crd.Body>
    </Crd>
  );
};

export default ContentBox;
