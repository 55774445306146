import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../../app/auth/authSlice";
import "../../../config/i18n";
import { seti18n } from "../../../config/i18n";
import { IChildren } from "../../shared/model/ChildrenModel";
import AuthLayout from "./components/AuthLayout";

const AppLayout = ({ children }: IChildren) => {
  const user = useSelector(selectUser);
  const [loadi18n, setLoadI18n] = useState(false);

  useEffect(() => {
    seti18n(setLoadI18n);
  }, []);

  return <>{loadi18n && <AuthLayout>{children}</AuthLayout>}</>;
};

export default AppLayout;
