import { IDragDropAnswer } from "../gamePlatform/models/gameQuestion";

export const gameQuestionData = {
  gui: "multichoice",
  sideBar: {
    timeToAnswer: 130,
    currentScore: 420,
    currentTime: 0,
    playstate: false,
    timeToNextAttempt: 0,
    currentQuestionNumber: 11,
    totalQuestionNumber: 20,
    startTime: 0,
    immediateStartTimeQuestion: true,
  },
  pointsTime: [
    {
      timeStart: 0,
      timeEnd: 41,
      pointGain: "+100 points for answering correctly in less than 40 seconds",
    },
    {
      timeStart: 40,
      timeEnd: 71,
      pointGain: "+80 points for answering correctly in less than 70 seconds",
    },
    {
      timeStart: 70,
      timeEnd: 91,
      pointGain: "+60 points for answering correctly in less than 90 seconds",
    },
    {
      timeStart: 90,
      timeEnd: 111,
      pointGain: "+40 points for answering correctly in less than 110 seconds",
    },
    {
      timeStart: 110,
      timeEnd: 121,
      pointGain: "+20 points for answering correctly in less than 120 seconds",
    },
    {
      timeStart: 120,
      timeEnd: 130,
      pointGain: "+10 points for answering correctly in less than 130 seconds",
    },
  ],
  carousels: [],
  questionItem: {
    question: "Se:\n[mela] ÷ 3 = 21\n· = - ([mela] ÷ 9)\nAllora · è uguale a:",
    questionDescription: null,
    answers: [
      {
        choice: "0",
        answer: "7",
      },
      {
        choice: "1",
        answer: "- 3",
      },
      {
        choice: "2",
        answer: "- 7",
      },
    ],
  },
  category: {
    id: 696,
    name: "CATEGORY.HUMANRESOURCES.FLOOR1.ORGANIZATION.ORGANIZATIONANALYSIS.TITLE",
    description: "humanresources_floor1_organization_organizationanalysis_0",
    color: "fca-bg-microgame-red",
    rgb: "#000000",
    depth: 4,
    gameId: "humanresources_game_0",
    subCategories: [],
    tutorEmail: "noreply.humanresources@fcagroup.com",
    splashEnabled: false,
    splashInstanceId: "",
    gameType: null,
    backMenu: "",
    operation: null,
    resources: true,
  },
  pageReload: null,
  shapesMap: [],
  itemsDraggable: [],
  backgroundImage: "",
  attemptCompleted: false,
  toolbarInfo: {
    title: "LEARNING GAME",
    lifes: [
      {
        status: true,
      },
      {
        status: true,
      },
      {
        status: false,
      },
      {
        status: false,
      },
      {
        status: false,
      },
    ],
    score: 420,
    level: "Human Resources",
    attempt: 5,
  },
};

export const dragDropSample: IDragDropAnswer = {
  active: {
    active: false,
  },
  id: 4,
  items: [
    {
      drag: true,
      id: 3,
      jqyoui_pos: 3,
      srcImage: "",
      title: "",
    },
  ],
  maxElement: null,
  name: "area4",
};
