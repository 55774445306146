import * as routeName from "./routeNameAuthType";
import routeMap from "./map";
import routeScene from "./scene";
import routeGame from "./game";
import routeMenu from "./menu";
import { SingleRoute } from "../utils/routes/models/routes";
import routeResources from "./resources";
import routeMicroGame from "./microgame";
import routeComments from "./comments";
import routeMetabase from "./metabase";

const routes: {
  list: { path: string; childrenRoute: SingleRoute[] }[];
  redirect: string;
} = {
  list: [
    {
      path: "/maps",
      childrenRoute: routeMap,
    },
    {
      path: "/links",
      childrenRoute: routeResources,
    },
    {
      path: "/microgames",
      childrenRoute: routeMicroGame,
    },
    {
      path: "/scenes",
      childrenRoute: routeScene,
    },
    {
      path: "/games",
      childrenRoute: routeGame,
    },
    {
      path: "/menu",
      childrenRoute: routeMenu,
    },
    {
      path: "/comments",
      childrenRoute: routeComments,
    },
    {
      path: "/metabases",
      childrenRoute: routeMetabase,
    },
  ],
  redirect: routeName.redirectPath,
};
export default routes;
