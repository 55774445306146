import React from "react";
import { mergeClass, classByArray } from "../../../functions/function";
import { FormControl } from "react-bootstrap";
import MultiSelectCustom from "./components/MultiSelectCustom";
import IconAction from "./components/IconAction";
import { selectType } from "../../../../../utils/forms/models/FormElementModel";
import { useTranslation } from "react-i18next";

const Select: React.FC<selectType> = ({
  children,
  className,
  options = [],
  iconAction,
  customSelect,
  error = false,
  multiply = false,
  changeSelect = () => {},
  value,
  disabled,
  clickAction = () => {},
  ...rest
}) => {
  const { t } = useTranslation();
  const classMerge: string = mergeClass(
    "shared-select",
    classByArray([error ? " error" : "", customSelect ? "customSelect" : ""])
  );
  return (
    <div>
      {multiply ? (
        <MultiSelectCustom
          disabled={disabled}
          options={options}
          value={value}
          error={error}
          {...rest}
          changeSelect={changeSelect}
        />
      ) : (
        <>
          <div className="d-flex">
            <FormControl
              as="select"
              value={value !== undefined && value !== "-1" ? value : "-1"}
              disabled={disabled}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                changeSelect(
                  event.target.value !== "-1" ? event.target.value : undefined
                )
              }
              className={mergeClass(classMerge, className)}
              {...rest}
            >
              {options.map((opt, index) => (
                <option key={opt.value} value={opt.value}>
                  {t(opt.label)}
                </option>
              ))}
            </FormControl>
            {iconAction && <IconAction onClick={clickAction}></IconAction>}
          </div>
        </>
      )}
    </div>
  );
};

export default Select;
