import React from "react";
import ToastPropsStyle from "../../../toast/models/ToastProps";
import ToastMsg from "../../../toast/ToastMsg";
import { ToastProps } from "../models/ToastProps";

const styleSuccess: ToastPropsStyle = {
  style: { color: "#565B6F", bgColor: "#fff" },
  icon: { color: "green", ico: "published_with_changes " },
};

const ToastSuccess = ({
  className,
  title,
  body,
  showToast = true,
  closeAction,
  ...rest
}: ToastProps) => {
  return (
    <ToastMsg
      showToast={showToast}
      className="shared-toastSuccess"
      toastStyle={styleSuccess}
      title={title}
      body={body}
      closeAction={() => closeAction && closeAction()}
    ></ToastMsg>
  );
};

export default ToastSuccess;
