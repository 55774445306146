import omit from "lodash.omit";
import http from "../../../../../../utils/http";
import { IMenuManagerItem } from "../model/config";

const urlMenuManager = "/menus";

export const createMenuItem = (newMenuItem: IMenuManagerItem): Promise<any> =>
  http.post(`${urlMenuManager}`, newMenuItem);

export const getMenuItems = (): Promise<IMenuManagerItem[]> =>
  http.get(`${urlMenuManager}`);

export const getMenuItemById = (idMenu: string): Promise<IMenuManagerItem> =>
  http.get(`${urlMenuManager}/${idMenu}`);

export const getMenuItemsByRole = (): Promise<IMenuManagerItem[]> =>
  http.get(`${urlMenuManager}/roles`);

export const updateMenuItem = (
  idMenu: string,
  editedMenuItem: IMenuManagerItem
): Promise<IMenuManagerItem> => {
  let newData = omit(editedMenuItem, ["_id"]);
  const ids = newData.childrenItems?.map((ele) => ele._id) || [];
  return http.put(`${urlMenuManager}/${idMenu}`, {
    ...newData,
    childrenItems: ids,
  });
};

export const deleteMenuItem = (idMenu: string): Promise<any> =>
  http.delete(`${urlMenuManager}/${idMenu}`);
