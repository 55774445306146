import React from "react";
import { useTranslation } from "react-i18next";
import { IBtnList } from "../../../../../app/auth/model/BtnList";
import CardShadow from "../../../design/card/CardShadow";
import Icon from "../../../icon/Icon";

interface IProp {
  title?: string;
  btnListLeft: IBtnList[];
  btnListRight: IBtnList[];
  clickAction: (action: string) => void;
}

const ToolbarBtn = ({
  title,
  btnListLeft,
  btnListRight,
  clickAction,
}: IProp) => {
  const { t } = useTranslation();
  return (
    <CardShadow className="toolbarBtn">
      {btnListLeft.map((ele, key) => (
        <Icon
          key={key}
          icon={ele.icon}
          colorStyle={ele.color}
          size="24px"
          disabled={ele.disabled}
          title={t(ele.title || "")}
          clickAction={() => clickAction(ele.action)}
        ></Icon>
      ))}
      <div>{title}</div>
      <div className="d-flex">
        {btnListRight.map((ele, key) => (
          <Icon
            key={key}
            icon={ele.icon}
            colorStyle={ele.color}
            size="24px"
            disabled={ele.disabled}
            title={t(ele.title || "")}
            clickAction={() => clickAction(ele.action)}
          ></Icon>
        ))}
      </div>
    </CardShadow>
  );
};

export default ToolbarBtn;
