import omit from "lodash.omit";
import http from "../../../../../../utils/http";

const urlPreferences = "/preferences";

export const getUserPreference = (userId: string): Promise<any> => {
  return http.get(`${urlPreferences}/${userId}`);
};

export const editUserPreference = (userPreference: any): Promise<any> => {
  const data = omit(userPreference, ["id"]);
  return http.patch(`${urlPreferences}/${userPreference.id}`, data);
};
export const getUserPreferenceOnline = (): Promise<any> => {
  return http.patch(`${urlPreferences}/online`);
};
export const editUserPreferenceOnline = (userPreference: any): Promise<any> => {
  return http.patch(`${urlPreferences}/online`, userPreference);
};

export const deleteUserPreference = (userId: string): Promise<any> => {
  return http.delete(`${urlPreferences}/${userId}`);
};
