import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { addErrorMessage } from "../../../../../app/errors/errorSlice";
import { AppThunk, RootState } from "../../../../../app/store";
import i18n from "../../../../../config/i18n";
import {
  IMicroBadgeResponse,
  IMyprogressResponse,
} from "./models/serviceResponse";
import { tabsNameProgress } from "./myProgressData";
import { getBadges, getMicroBadges } from "./services/myprogress.service";

export interface IMyProgressSlice {
  keyTabProgress: string;
  badgesList: IMyprogressResponse | undefined;
  microbadgesList: IMicroBadgeResponse | undefined;
  clickedAreaId: string;
}
const initialState: IMyProgressSlice = {
  keyTabProgress: tabsNameProgress.active,
  badgesList: undefined,
  microbadgesList: undefined,
  clickedAreaId: "",
};

export const myProgressSlice = createSlice({
  name: "myProgress",
  initialState,
  reducers: {
    setKeyTabProgress: (state, action: PayloadAction<string>) => {
      state.keyTabProgress = action.payload;
    },
    setBadgesList: (state, action: PayloadAction<IMyprogressResponse>) => {
      state.badgesList = action.payload;
    },
    setMicrobadgesList: (state, action: PayloadAction<IMicroBadgeResponse>) => {
      state.microbadgesList = action.payload;
    },
    setClickedAreaId: (state, action: PayloadAction<string>) => {
      state.clickedAreaId = action.payload;
    },
  },
});

export const {
  setKeyTabProgress,
  setBadgesList,
  setMicrobadgesList,
  setClickedAreaId,
} = myProgressSlice.actions;

export const getListBadges = (): AppThunk => (dispatch) => {
  getBadges().then(
    (res) => dispatch(setBadgesList(res)),
    (err) =>
      dispatch(
        addErrorMessage({
          title: i18n.t("ERRORSMSG.LOADINGBADGES"),
        })
      )
  );
};

export const getMicrobadgesList =
  (areaId: string): AppThunk =>
  (dispatch) => {
    getMicroBadges(areaId).then(
      (res) => {
        dispatch(setMicrobadgesList(res));
        dispatch(setClickedAreaId(areaId));
      },
      (err) =>
        dispatch(
          addErrorMessage({
            title: i18n.t("ERRORSMSG.LOADINGBADGES"),
          })
        )
    );
  };

export const selectKeyTabsProgress = (state: RootState) =>
  state.myProgress.keyTabProgress;
export const selectBadgesList = (state: RootState) =>
  state.myProgress.badgesList;
export const selectMicrobadgesList = (state: RootState) =>
  state.myProgress.microbadgesList;
export const selectClickedAreaId = (state: RootState) =>
  state.myProgress.clickedAreaId;

export default myProgressSlice.reducer;
