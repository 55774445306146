import http from "../../../../../../utils/http";
import { IDataUserRole, IRole, IUserRole } from "./../models/userRole";

const urlRoles = "/ids";
const realm = "g4mobility";

export const getUsers = ({
  searchQuery,
}: {
  searchQuery?: string;
}): Promise<IUserRole> => {
  const query =
    searchQuery && searchQuery.length ? `?search=${searchQuery}` : "";
  return http.get(`${urlRoles}/_search/${realm}${query}`);
};
export const getUsersRoleBySubject = (subject: string): Promise<IUserRole> => {
  return http.get(`${urlRoles}/_find/${realm}/${subject}`);
};
export const getRolesAvailable = (): Promise<IRole[]> => {
  return http.get(`${urlRoles}/_roles/${realm}`);
};

export const addUser = (dataUser: IDataUserRole): Promise<IUserRole> => {
  return http.post(`${urlRoles}/${realm}`, dataUser);
};
export const editUser = (dataUser: IUserRole): Promise<IUserRole> => {
  return http.patch(`${urlRoles}/_roles/${realm}/${dataUser.subject}`, {
    roles: dataUser.roles.map((ele) => ele.name),
  });
};
