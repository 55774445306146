import omit from "lodash.omit";
import http from "../../../../utils/http";
import { IIdResponse } from "../../fabricJs/models/servicesResponse";
import { IBackgroundResource } from "../model/backgroundResource";
import { IResource } from "../model/layout";

const urlResources = `/links`;
const urlHyperlinks = `/hyperlinks`;

export const getResourceById = (id: string): Promise<any[]> =>
  http.get(`${urlResources}/${id}/resource`);

export const getHyperlinkByCategoryType = (
  id: string,
  type?: string
): Promise<any[]> =>
  http.get(`${urlHyperlinks}`, {
    params: {
      categories: id,
      type,
    },
  });

export const getAllHyperlinks = (): Promise<any[]> =>
  http.get(`${urlHyperlinks}`);

export const editResourceById = (
  resource: IResource,
  idResource: string
): Promise<IIdResponse> => {
  const newResource = omit(resource, ["id", "id_temp"]);
  return http.put(`${urlResources}/${idResource}/resource`, newResource);
};

export const saveResourceById = (resource: IResource): Promise<IIdResponse> => {
  const newResource = {
    ...resource,
  };
  return http.post(`${urlHyperlinks}`, newResource);
};

export const deleteResourceSelected = (resourceId: string): Promise<any> => {
  return http.delete(`${urlHyperlinks}/${resourceId}`);
};

export const updateResourceById = (
  resourceId: string,
  resource: IResource
): Promise<IIdResponse> => {
  let editedResource = omit(resource, ["categories", "_id", "__v", "id"]);
  return http.put(`${urlHyperlinks}/${resourceId}`, editedResource);
};

// BACKGROUND

export const getBgResourceById = (id: string): Promise<any> =>
  http.get(`${urlResources}/${id}/backgrounds`);

export const saveBgForResource = (
  bg: IBackgroundResource,
  idResource: string
): Promise<IIdResponse> => {
  const newBg = omit(bg, ["id", "id_temp"]);
  return http.post(`${urlResources}/${idResource}/backgrounds`, newBg);
};

export const editBgForResource = (
  bg: IBackgroundResource,
  idResource: string
): Promise<IIdResponse> => {
  const newBg = omit(bg, ["id", "_id", "id_temp", "gameId"]);
  return http.put(`${urlResources}/${idResource}/backgrounds/${bg._id}`, newBg);
};

export const deleteBgForResource = (
  bg: IBackgroundResource,
  idResource: string
): Promise<IIdResponse> => {
  return http.delete(`${urlResources}/${idResource}/backgrounds/${bg._id}`);
};
