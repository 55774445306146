import React from "react";
import { mergeClass } from "../../functions/function";
interface LabelProps extends React.HTMLAttributes<HTMLOrSVGElement> {}
const HeadingH3 = ({ children, className, ...rest }: LabelProps) => {
  return (
    <h3 className={mergeClass("heading-3", className)} {...rest}>
      {children}
    </h3>
  );
};

export default HeadingH3;
