import React from "react";
import Input from "../../components/shared/design/form/input/Input";
import Select from "../../components/shared/design/form/select/Select";
import TextArea from "../../components/shared/design/form/textArea/TextArea";
import Switch from "../../components/shared/design/form/switch/Switch";
import CheckboxList from "../../components/shared/design/form/checkboxList/CheckboxList";
import { Row, Col } from "react-bootstrap";
import Radio from "../../components/shared/design/form/radio/Radio";
import Progress from "../../components/shared/design/form/progress/Progress";
import { FormControlCustomModel } from "./models/FormElementModel";
import AutoComplete from "../../components/shared/autocomplete/AutoComplete";

const FormControlCustom = ({
  isSubmit,
  errors,
  dataElement,
  name,
  value,
  changeValue = () => {},
  typeElement = "input",
  options = [],
  onBlur,
}: FormControlCustomModel) => {
  return (
    <>
      {typeElement === "input" && (
        <Input
          name={name}
          error={isSubmit && !!errors && !!errors[name]}
          value={value}
          onChangeAction={changeValue}
          onBlur={onBlur}
          {...dataElement}
        />
      )}
      {typeElement === "select" && (
        <Select
          error={isSubmit && !!errors && !!errors[name]}
          name={name}
          options={options}
          value={value}
          {...dataElement}
          changeSelect={changeValue}
        />
      )}
      {typeElement === "textarea" && (
        <TextArea
          name={name}
          value={value}
          onBlur={onBlur}
          {...dataElement}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
            changeValue(event.target.value);
          }}
        />
      )}
      {typeElement === "radio" && (
        <Row>
          {options.map((ele, index) => {
            return (
              <Col xs="auto" key={index}>
                <Radio
                  name={name}
                  value={ele.value}
                  label={ele.label}
                  checked={ele.value === value}
                  {...dataElement}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    changeValue(event.target.value)
                  }
                />
              </Col>
            );
          })}
        </Row>
      )}
      {typeElement === "checkbox" && (
        <CheckboxList
          value={value}
          options={options}
          name={name}
          changeChecked={changeValue}
        />
      )}
      {typeElement === "progress" && (
        <Progress
          value={value}
          changeProgress={(event: React.ChangeEvent<HTMLInputElement>) => {
            changeValue(event.target.value);
          }}
        />
      )}
      {typeElement === "switch" && (
        <Switch
          className="ml-auto"
          error={isSubmit && !!errors && !!errors[name]}
          name={name}
          checked={value}
          typeCheck={dataElement.type}
          noRequired={dataElement.noRequired}
          changeSwitch={changeValue}
        ></Switch>
      )}
      {typeElement === "autocomplete" && (
        <AutoComplete
          error={isSubmit && !!errors && !!errors[name]}
          name={name}
          value={value}
          options={options}
          onChangeAction={changeValue}
          disabled={dataElement.disabled ? dataElement.disabled : false}
          {...dataElement}
        />
      )}
    </>
  );
};

export default FormControlCustom;
